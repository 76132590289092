/** @format */
import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getTimeslots } from '../../redux/userApiCalls';
import {
	getMyAppointments,
	paymentStatusUpdate,
	paymentStatusvisitedPatients,
} from '../../redux/doctorRedux/doctorApiCalls';
import { getDoctorVisitedPatientsforParticularDate } from '../../redux/adminRedux/adminApiCalls';
// import Accordion from 'react-bootstrap/Accordion';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getMyAppointmentsReset } from '../../redux/doctorRedux/Appointment_Management/getMyAppointments';

import Form from 'react-bootstrap/Form';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { TimeSlotGetReset } from '../../redux/getTimeSlots';
import { useParams } from 'react-router-dom';

const GetMyAppointmentsScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	//Success and failure allMessages
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	// Accordian Collapse
	const [open, setOpen] = useState('1');
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const doctorAppointmentDates = doctorInfo?.user?.appointmentDates;
	console.log(doctorInfo?.user?.specialityId);
	//Modal Open
	const [lgShow, setLgShow] = useState(false);

	//Date Format
	var days = [];
	let slotArrayVisited = [];
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ('0' + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);
	const today1 = new Date();
	const year = today1.getFullYear();
	const month = today1.getMonth() + 1; // Months are zero-indexed
	const day = today1.getDate();

	// Form a number in YYYYMMDD format
	const numericDate = year * 10000 + month * 100 + day;

	let dateModified = numericDate;

	//Retrieving Specialities Data
	useEffect(() => {
		dispatch(
			getMyAppointments(
				doctorInfo?.user?._id, //doc id
				dateModified,
				clinicName
			)
		);
	}, [dispatch]);

	//Retrieving Bookings Data of selected Doctor
	const data1 = useSelector((state) => state.DoctorGetMyAppointments);
	console.log(data1);
	const { getMyBookings, loading, error, success } = data1;
	console.log(getMyBookings);
	const [dateOptionNumeric, setDateOptionNumeric] = useState('Choose Date');
	//Date dropdown
	const [dateOption, setDateOption] = useState(`${day}-${month}-${year}`);
	const handleDateSelect = (eventKey) => {
		// slotArray = [];
		const dateString = doctorAppointmentDates[eventKey].date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ('0' + dateString.slice(6, 8)).slice(-2);
		setDateOption(`${day}-${month}-${year}`);
		setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
		slotArrayVisited = [];
	};

	//Search button function
	const submitHandler = async () => {
		dispatch(
			getMyAppointments(
				doctorInfo?.user?._id, //doc id
				dateOptionNumeric,
				clinicName
			)
		);
	};

	//creating an array containing set of all the visited bookings slots
	console.log(getMyBookings?.data?.data);
	let temp = [];

	//creating an array containing set of all the bookings slots except onHold
	let slotArrayNotVisited = [];
	temp = [];
	for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
		if (
			!temp.includes(getMyBookings?.data?.data[i]?.slot) &&
			getMyBookings?.data?.data[i]?.slot !== 'onHold'
		) {
			slotArrayNotVisited.push([getMyBookings?.data?.data[i]?.slot]); //[getMyBookings?.data?.data[i]?.slot]
			temp.push(getMyBookings?.data?.data[i]?.slot);
		}
	}
	// console.log(slotArrayNotVisited[0][0].split("-")[0]);
	console.log('LINE146');
	slotArrayNotVisited.sort(function (a, b) {
		return parseInt(a[0].split('-')[0]) - parseInt(b[0].split('-')[0]);
	});
	console.log(slotArrayNotVisited);
	console.log('line151');
	for (let i = 0; i < getMyBookings?.data?.data?.length; i++) {
		for (let j = 0; j < slotArrayNotVisited?.length; j++) {
			if (slotArrayNotVisited[j][0] === getMyBookings?.data?.data[i]?.slot) {
				slotArrayNotVisited[j].push({
					patientNo: slotArrayNotVisited[j].length,
					_id: getMyBookings?.data?.data[i]?._id,
					name: getMyBookings?.data?.data[i]?.name,
					slot: getMyBookings?.data?.data[i]?.slot,
					slotCount: getMyBookings?.data?.data[i]?.slotCount,
					mobileNo: getMyBookings?.data?.data[i]?.userPhone,
					onHold: getMyBookings?.data?.data[i]?.onHold,
					user: getMyBookings?.data?.data[i]?.user,
					date: getMyBookings?.data?.data[i]?.scheduledDate,
				});
			}
		}
	}
	const viewAppointmentHandler = (id, name, date) => {
		console.log(id, name, date);
		history(`/clinic/bhuvanshomeopathy/userappointment`, {
			state: {
				id: id,
				name: name,
				docname: doctorInfo?.user?.name,
				date: date,
			},
		});
	};

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	const exportToExcel = async () => {
		let tempObj =
			getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data
				?.visited_patients;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				'Patient Name': tempObj[i].name,
				'Slot ': tempObj[i].slot,
				'Mobile No': tempObj[i].mobilenumber,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + 'dataaaaaa');
		const ws = XLSX.utils.json_to_sheet(tempArr);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, doctorInfo?.user?._id + '_' + days[0]);
		console.log('saved');
	};

	//ALerts//////////////////////////////////
	const [showPopup, setShowPopup] = useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(getMyAppointmentsReset());
	};
	const [showPopup1, setShowPopup1] = useState(false);

	const [showPopup2, setShowPopup2] = useState(false);

	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log('error');
		dispatch(TimeSlotGetReset());
	};
	const [patientsToBeVisited, setPatientsToBeVisited] = useState('d-block');
	const [patientsVisited, setVisitedPatients] = useState('d-none');
	const handlePatients = (take) => {
		if (take === '1') {
			setPatientsToBeVisited('d-block');
			setVisitedPatients('d-none');
		} else {
			setPatientsToBeVisited('d-none');
			setVisitedPatients('d-block');
		}
		console.log('you are in');
	};

	const [visitedDate, setVisitedDate] = useState('');
	const handleDateSelect1 = (eventKey) => {
		setVisitedDate(eventKey);
		setSearchClicked(false);
	};
	const [searchClicked, setSearchClicked] = useState(false);
	const searchForVisited = async () => {
		if (visitedDate == '') {
			alert('Please select the date');
		} else {
			const tempArr1 = visitedDate.split('-');
			const dateModified1 = tempArr1[0] + '' + tempArr1[1] + '' + tempArr1[2];
			setSearchClicked(true);
			dispatch(
				getDoctorVisitedPatientsforParticularDate(
					doctorInfo?.user?._id,
					parseInt(dateModified1),
					clinicName
				)
			);
		}
	};
	const getVisiPat = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);
	console.log(getVisiPat);

	const today = new Date().toISOString().split('T')[0];
	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item onClick={() => handlePatients('1')}>
					Patients To Be visited
				</Breadcrumb.Item>
				<Breadcrumb.Item onClick={() => handlePatients('2')}>
					Visited Patients
				</Breadcrumb.Item>
			</Breadcrumb>
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			{getTimeSlotserror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup2}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getTimeSlotserror}
				</Message>
			)}
			{getTimeSlotsloading && <Loader />}
			<div className={patientsToBeVisited}>
				<h1 className='offset-md-5'>Appointments </h1>
				<div className='row mt-5 mb-3'>
					<h6 className='col-2 offset-md-1'>
						Select the Date{' '}
						<span className='text-danger'>
							<b>*</b>
						</span>
					</h6>
					<DropdownButton
						className='col-2 btn'
						key='down-centered'
						id={`dropdown-button-drop-down-centered`}
						drop='down-centered'
						variant='success'
						title={dateOption}
						onSelect={handleDateSelect}>
						<div className='dropScroll'>
							{days?.map((day, index) => (
								<Dropdown.Item key={index} eventKey={index}>
									{day?.currDay}
								</Dropdown.Item>
							))}
						</div>
					</DropdownButton>
					<Button
						id='searchButton'
						className='col-2 mt-2 btn-primary'
						onClick={submitHandler}>
						Search
					</Button>
				</div>
				<Container>
					{slotArrayNotVisited.length > 0 ? (
						slotArrayNotVisited.map((slot1, index) => (
							<Accordion open={open} toggle={toggle}>
								<AccordionItem>
									<AccordionHeader targetId={index}>{slot1[0]}</AccordionHeader>
									<AccordionBody accordionId={index}>
										<table className='table table-striped'>
											<thead>
												<tr>
													<th></th>
													<th scope='col'>Patient No</th>
													<th scope='col'>Patient Name</th>
													<th scope='col'>Patient Mobile Number</th>
													<th scope='col'>History</th>
												</tr>
											</thead>
											{slot1?.slice(1, slot1.length).map(
												({
													_id,
													name,
													slot, //onhold
													slotCount, // onhold:1
													mobileNo,
													patientNo,
													onHold,
													user,
													date,
												}) => (
													<tbody>
														<tr>
															<td></td>
															<td>{patientNo}</td>
															<td>{name}</td>
															<td>{mobileNo}</td>
															<button
																className='btn btn-success'
																onClick={() =>
																	viewAppointmentHandler(user, name, date)
																}>
																View Prescriptions
															</button>
														</tr>
													</tbody>
												)
											)}
										</table>
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						))
					) : (
						<h5 className='text-danger header-center'>
							No appointments are booked for the selected date.
						</h5>
					)}
				</Container>
			</div>
			<div className={patientsVisited}>
				<div className='row mt-5 mb-3'>
					<h6 className='col-2 offset-md-1'>
						Select the Date{' '}
						<span className='text-danger'>
							<b>*</b>
						</span>
					</h6>
					<input
						type='date'
						className='form-control col-2 mt-2 mr-5'
						onChange={(e) => handleDateSelect1(e.target.value)}
						max={today}
					/>
					<Button
						id='searchButton1'
						className='col-2 mt-2 btn-primary'
						onClick={searchForVisited}>
						Search
					</Button>
				</div>
				<button
					className='btn btn-success offset-md-9 mb-2'
					onClick={(e) => exportToExcel()}>
					{' '}
					<i className='fa fa-download'></i>&nbsp;Download
				</button>
				<Container>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th></th>
								<th scope='col'>Patient No</th>
								<th scope='col'>Patient Name</th>
								<th scope='col'>Slot</th>
								<th scope='col'>Patient Mobile Number</th>
							</tr>
						</thead>
						{visitedDate !== '' &&
							searchClicked &&
							getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients?.map(
								({ name, mobilenumber, prescriptionId, slot }, index) => (
									<tbody>
										<tr>
											<td></td>
											<td>{index + 1}</td>
											<td>{name}</td>
											<td>{slot}</td>
											<td>{mobilenumber}</td>
										</tr>
									</tbody>
								)
							)}
					</table>
				</Container>
			</div>
		</>
	);
};

export default GetMyAppointmentsScreen;
