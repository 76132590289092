/** @format */

import axios from 'axios';
import {
	createPrescriptionFailure,
	createPrescriptionReset,
	createPrescriptionStart,
	createPrescriptionSuccess,
} from './createPrescription.js';
import { SERVER_URL } from '../../App';
import {
	editPrescriptionFailure,
	editPrescriptionReset,
	editPrescriptionStart,
	editPrescriptionSuccess,
} from './editPrescription.js';

import {
	viewPatientHistoryFailure,
	viewPatientHistoryRequest,
	viewPatientHistorySuccess,
	viewPatientHistoryReset,
} from './viewPatientHistory.js';

import {
	getPrescriptionFailure,
	getPrescriptionRequest,
	getPrescriptionSuccess,
	getPrescriptionReset,
} from './getPrescription.js';

import {
	getMyIssueCategoryVitalsRequest,
	getMyIssueCategoryVitalsSuccess,
	getMyIssueCategoryVitalsFailure,
	getMyIssueCategoryVitalsReset,
} from './getCategoriesVitals.js';

import {
	getUserDataByIdRequest,
	getUserDataByIdSuccess,
	getUserDataByIdFailure,
} from './getUserDataById';

import {
	getPrescriptionByCategoryFailure,
	getPrescriptionByCategoryRequest,
	getPrescriptionByCategorySuccess,
	getPrescriptionByCategoryReset,
} from './getPrescriptionByCategory';

import {
	getPrescriptionByDateFailure,
	getPrescriptionByDateRequest,
	getPrescriptionByDateSuccess,
} from './getPrescriptionByDate';

import {
	getPrescriptionIdsofCategoryFailure,
	getPrescriptionIdsofCategoryRequest,
	getPrescriptionIdsofCategorySuccess,
	getPrescriptionIdsofCategoryReset,
} from './getPrescriptionIdsofCategory';

import {
	scheduleAppointmentDateByDoctorFailure,
	scheduleAppointmentDateByDoctorStart,
	scheduleAppointmentDateByDoctorSuccess,
} from './scheduleAppointmentDateByDoctor.js';

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';

import {
	get_scheduleAppointments_To_Doctor_ReceptionFailure,
	get_scheduleAppointments_To_Doctor_ReceptionStart,
	get_scheduleAppointments_To_Doctor_ReceptionSuccess,
} from './getScheduleAppointmentsForNotificationToDoctorReception';
import {
	getuserByIdForPrescriptionFailure,
	getuserByIdForPrescriptionRequest,
	getuserByIdForPrescriptionSuccess,
} from './getuserByIdForPrescription.js';
import {
	onSpotPrescriptionStart,
	onSpotPrescriptionSuccess,
	onSpotPrescriptionFailure,
} from './onSpotPrescription.js';

export const onSpotPrescription =
	(
		vitals,
		category,
		title,
		doctorId,
		doctorName,
		medicines,
		username,
		userId,
		bookedDate,
		userIdx,
		opConsultationFee,
		prescriptionTemplate,
		templateName,
		clinicname,
		specialityName
	) =>
	async (dispatch) => {
		try {
			// console.log(labTests);
			console.log('create prescription called');
			dispatch(onSpotPrescriptionStart());
			const token = localStorage.getItem('token-user');
			const username1 = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
					username: username1,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/prescription/onSpotPrescription`,
				{
					// labTests,
					vitals,
					category,
					title,
					doctorId,
					doctorName,
					medicines,
					username,
					userId,
					bookedDate,
					// specialityId,
					// specialityName,
					userIdx,
					opConsultationFee,
					prescriptionTemplate,
					templateName,
					specialityName,
				},
				config
			);

			console.log('backend successfull');
			dispatch(onSpotPrescriptionSuccess(data));
			dispatch(allMessagesSuccess('Prescription created Successfully '));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			console.log(error);
			dispatch(
				onSpotPrescriptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating prescription'));
		}
	};
export const createPrescription =
	(
		vitals,
		category,
		title,
		doctorId,
		doctorName,
		medicines,
		username,
		userId,
		bookedDate,
		specialityName,
		bookingId,
		userIdx,
		prescriptionTemplate,
		templateName,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('create prescription called');
			dispatch(createPrescriptionStart());
			const token = localStorage.getItem('token-user');
			const username1 = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
					username: username1,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/prescription/createPrescription`,
				{
					vitals,
					category,
					title,
					doctorId,
					doctorName,
					medicines,
					username,
					userId,
					bookedDate,
					specialityName,
					bookingId,
					userIdx,
					prescriptionTemplate,
					templateName,
				},
				config
			);

			console.log('backend successfull');
			dispatch(createPrescriptionSuccess(data));
			dispatch(allMessagesSuccess('Prescription created Successfully '));
		} catch (error) {
			console.log(error);
			dispatch(
				createPrescriptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating prescription'));
		}
	};

export const editPrescription =
	(
		vitals,
		category,
		title,
		doctorId,
		doctorName,
		summary,
		advice,
		medicines,
		username,
		userId,
		bookedDate,
		prescriptionId,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editPrescriptionStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.patch(
				`${SERVER_URL}/api/v1/prescription/getPrescription/${prescriptionId}`,
				{
					vitals,
					category,
					title,
					doctorId,
					doctorName,
					summary,
					advice,
					medicines,
					username,
					userId,
					bookedDate,
				},
				config
			);

			// console.log(data.user);
			dispatch(editPrescriptionSuccess(data));
			dispatch(allMessagesSuccess('Prescription edited Successfully '));
		} catch (error) {
			console.log(error);
			dispatch(
				editPrescriptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing prescription'));
		}
	};

// Reduntant - remove while re-factoring(Ganesh)
export const viewPatientHistory =
	(userId, name, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(viewPatientHistoryRequest());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getAllPrescription/${userId}/${name}/${username}`,
				config
			);

			// console.log(data.user);
			dispatch(viewPatientHistorySuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				viewPatientHistoryFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching history'));
		}
	};

export const getPrescription =
	(prescriptionId, clinicname) => async (dispatch) => {
		try {
			console.log('11223344', prescriptionId);
			dispatch(getPrescriptionRequest());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getPrescription/${prescriptionId}/${username}`,
				config
			);

			// console.log(data.user);
			dispatch(getPrescriptionSuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				getPrescriptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching prescription'));
		}
	};

export const getCategoriesVitals =
	(userId, name, userIdx, clinicname) => async (dispatch) => {
		try {
			console.log(name);
			dispatch(getMyIssueCategoryVitalsRequest());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username: username,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getCategoriesVitals/${userId}/${name}/${userIdx}`,
				config
			);

			// console.log(data.user);
			dispatch(getMyIssueCategoryVitalsSuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				getMyIssueCategoryVitalsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching information'));
		}
	};

export const getUserDataById =
	(id, name, userIdx, clinicname) => async (dispatch) => {
		try {
			console.log('11223344' + id + name);
			dispatch(getUserDataByIdRequest());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/getuserById/${username}`,
				{ id, name, userIdx },
				config
			);

			console.log(data);
			dispatch(getUserDataByIdSuccess(data));
		} catch (error) {
			dispatch(
				getUserDataByIdFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching patient data'));
		}
	};

export const getPrescriptionByCategory =
	(userId, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getPrescriptionByCategoryRequest());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getPrescriptionByCategory/${userId}`,
				config
			);

			// console.log(data.user);
			dispatch(getPrescriptionByCategorySuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				getPrescriptionByCategoryFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching prescription data'));
		}
	};

export const getPrescriptionByDate =
	(userId, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getPrescriptionByDateRequest());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getPrescriptionByDate/${userId}`,
				config
			);

			// console.log(data.user);
			dispatch(getPrescriptionByDateSuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				getPrescriptionByDateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching prescription data'));
		}
	};

export const getPrescriptionIdsofCategory =
	(userId, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getPrescriptionIdsofCategoryRequest());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getPrescriptionIdsofCategory/${userId}`,
				config
			);

			// console.log(data.user);
			dispatch(getPrescriptionIdsofCategorySuccess(data));
		} catch (error) {
			console.log(error);
			dispatch(
				getPrescriptionIdsofCategoryFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching prescription data'));
		}
	};
/////////////////////////////////////////////Schedule Appointments//////////////////////////////////////////////
export const scheduleAppointmentDateByDoctorApiCall =
	(userId, name, date, doctor, doctorname, message, clinicname) =>
	async (dispatch) => {
		try {
			console.log('line 422', userId, name, date, doctor, doctorname, message);
			//yyyy-mm-dd
			dispatch(scheduleAppointmentDateByDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/prescription/scheduleAppointmentDate`,
				{ userId, name, date, doctor, doctorname, message },
				config
			);

			// console.log(data.user);
			dispatch(scheduleAppointmentDateByDoctorSuccess(data));
			dispatch(allMessagesSuccess('Succesfully created appointment'));
		} catch (error) {
			console.log(error);
			dispatch(allMessagesFailure('Please try again later'));
			dispatch(
				scheduleAppointmentDateByDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating appointment'));
		}
	};

export const get_scheduleAppointments_To_Doctor_Reception =
	(date, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			//yyyy-mm-dd
			dispatch(get_scheduleAppointments_To_Doctor_ReceptionStart());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/get_scheduleAppointments_To_Doctor_Reception/${date}/${username}`,
				config
			);

			// console.log(data.user);
			dispatch(
				get_scheduleAppointments_To_Doctor_ReceptionSuccess(data.data.data)
			);
		} catch (error) {
			console.log(error);
			dispatch(
				get_scheduleAppointments_To_Doctor_ReceptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching appointments'));
		}
	};

export const getuserByIdForPrescription =
	(id, name, clinicname) => async (dispatch) => {
		try {
			console.log('11223344' + id + name);
			dispatch(getuserByIdForPrescriptionRequest());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username,
					clinicname: 'bhuvanshomeopathy',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/prescription/getuserByIdForPrescription/${id}/${name}`,

				config
			);

			console.log(data);
			dispatch(getuserByIdForPrescriptionSuccess(data));
		} catch (error) {
			dispatch(
				getuserByIdForPrescriptionFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching prescriptions'));
		}
	};
