/** @format */

import React, { useEffect } from 'react';
import {
	Container,
	Navbar,
	Nav,
	Button,
	Card,
	Row,
	Col,
} from 'react-bootstrap';
import CountUp from 'react-countup';
import './styles.css';
import Hero from './Hero';
import Services from './Services';

function App() {
	useEffect(() => {
		const images = document.querySelectorAll('.gallery-item img');
		images.forEach((img) => {
			img.onload = () => {
				img.classList.add('loaded');
			};
		});
	}, []);

	return (
		<div className='App'>
			<Hero />

			<Container id='about' className='my-5'>
				<h2 className='text-center'>About Us</h2>
				<Row className='align-items-center'>
					<Col md={4} className='text-center'>
						<img
							src='/Doc_new_bhuvans.jpg'
							className='img-fluid rounded-circle'
							alt='About Us'
						/>
					</Col>
					<Col md={8}>
						<h3>Excellence in Homeopathy in Kamareddy</h3>
						<p>
							Experience the Healing Touch of Nature with Dr. Pathuri
							Rajashekhar Reddy at Bhuvan's Homeopathy. With over two decades of
							expertise, we provide personalized, natural remedies that restore
							balance and vitality. Join us on your journey to holistic
							well-being today.
						</p>
						<Row>
							<Col md={4} className='text-center'>
								<CountUp start={0} end={25} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />
											<p>Years of Experience</p>
										</div>
									)}
								</CountUp>
							</Col>
							<Col md={4} className='text-center'>
								<CountUp start={0} end={25000} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />+<p>Happy Patients</p>
										</div>
									)}
								</CountUp>
							</Col>
							{/* <Col md={4} className='text-center'>
								<CountUp start={0} end={20} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />
											<p>Dentists</p>
										</div>
									)}
								</CountUp>
							</Col> */}
						</Row>
					</Col>
				</Row>
			</Container>

			<Services />

			<Container id='gallery' className='my-5'>
				<h2 className='text-center'>Clinic Gallery</h2>
				<Row>
					<Col md={4} className='gallery-item'>
						<img
							src='/imagegallery1.jpg'
							className='img-fluid'
							alt='Gallery 1'
						/>
					</Col>
					<Col md={4} className='gallery-item'>
						<img
							src='/bhuvans_clinic2.jpg'
							className='img-fluid'
							alt='Gallery 2'
						/>
					</Col>
					<Col md={4} className='gallery-item'>
						<img
							src='/imagegallery2.jpg'
							className='img-fluid'
							alt='Gallery 3'
						/>
					</Col>
				</Row>
			</Container>

			{/* <footer
				className='text-center py-4'
				style={{ backgroundColor: '#f8f9fa' }}>
				<Container>
					<Row>
						<Col>
							<iframe
								frameborder='0'
								scrolling='no'
								marginheight='0'
								marginwidth='0'
								id='gmap_canvas'
								src="https://maps.google.com/maps?q=bhuvan's%20homeopathy%20clinic%20kamareddy&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
						</Col>
						<Col>
							<h5>Get in Touch</h5>
							<p>
								<i className='fas fa-phone'></i> +91-9494260222
							</p>
							<p>
								<i className='fas fa-envelope'></i> kavitha.dental@gmail.com
							</p>
						</Col>
						<Col>
							<h5>Follow Us</h5>
							<div className='social-icons'>
								<i className='fab fa-facebook-f'></i>
								<i className='fab fa-twitter'></i>
								<i className='fab fa-instagram'></i>
								<i className='fab fa-linkedin-in'></i>
							</div>
						</Col>
					</Row>
				</Container>
			</footer> */}
		</div>
	);
}

export default App;
