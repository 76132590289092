/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import Errortemplate from '../../Components/Errortemplate';
import { Link } from 'react-router-dom';
import { login, resendOtpAdmin } from '../../redux/adminRedux/adminApiCalls';
import Message from '../../Components/Message';

import {
	doctorLogin1,
	verifyCode,
	resendOtp,
} from '../../redux/doctorRedux/doctorApiCalls';
import {
	receptionLogin,
	resendOtpReception,
	verifyCodeReception,
} from '../../redux/receptionRedux/receptionApiCalls';
///reset calls

import { loginDoctorReset } from '../../redux/doctorRedux/MyProfile_Management/doctorLogin';
import { verifyCodeDoctorReset } from '../../redux/doctorRedux/MyProfile_Management/VerifyCode';
import { resendOtpDoctorReset } from '../../redux/doctorRedux/MyProfile_Management/resendOtp';

import { loginReceptionReset } from '../../redux/receptionRedux/MyProfile_Management/receptionLogin';
import { verifyCodeReceptionReset } from '../../redux/receptionRedux/MyProfile_Management/VerifyCode';
import { resendOtpReceptionReset } from '../../redux/receptionRedux/MyProfile_Management/resendOtp';

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';
import { useParams } from 'react-router-dom';

const renderButton = (buttonProps) => {
	return (
		<button
			type='button'
			className='btn btn-danger col-md-5 offset-md-7 mt-3'
			{...buttonProps}>
			{buttonProps.remainingTime !== 0
				? `Please wait for ${buttonProps.remainingTime} sec`
				: 'Resend'}
		</button>
	);
};
const renderTime = () => React.Fragment;

const AdminLoginScreens = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	//success and error//////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const doctorVerify = useSelector((state) => state.doctorVerifyCode);
	const doctorLoginsuccess = doctorLogin.success;
	const doctorLoginerror = doctorLogin.error;
	const doctorLoginloading = doctorLogin.loading;

	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const receptionVerify = useSelector((state) => state.receptionVerifyCode);
	const receptionLoginsuccess = receptionLogin.success;
	const receptionLoginerror = receptionLogin.error;
	const receptionLoginloading = receptionLogin.loading;
	console.log(receptionLoginerror);
	///////////////////////////////////////////////////////////
	const [username, setusername] = useState('');
	const [password, setPassword] = useState('');
	const [isMobile, setIsMobile] = useState(false);
	const [generateOTPBtn, setGenerateOTPBtn] = useState(
		'btn btn-success mt-3 mb-3 ml-3'
	);
	const [OTP, setOTP] = useState('');
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);
	const [verifyOTP, setVerifyOTP] = useState('d-none');
	const { clinicName } = useParams();

	//const receptionLogin1 = useSelector((state) => state.receptionLogin1);
	// const { loading, error, receptionInfo, success } = receptionLogin1;

	console.log(clinicName);
	// const { loading, error, doctorInfo, success } = doctorLogin;
	console.log(doctorLogin.doctorInfo);
	useEffect(() => {
		if (doctorLogin.doctorInfo) {
			console.log('222');
			history(`/clinic/bhuvanshomeopathy/doctor/patientstobevisited`);
			const now = new Date();
			const ISTOffset = 330;
			const istTime = new Date(new Date().getTime() + ISTOffset * 60000);
			const customFormat = `${istTime.getDate()}`;
			localStorage.setItem('date', customFormat);
		}
	}, [history, doctorLogin.doctorInfo]);
	useEffect(() => {
		if (receptionInfo) {
			console.log(receptionInfo);
			console.log('222');
			history(`/clinic/bhuvanshomeopathy/reception/booking`);
			const now = new Date();
			const ISTOffset = 330;
			const istTime = new Date(new Date().getTime() + ISTOffset * 60000);
			const customFormat = `${istTime.getDate()}`;
			localStorage.setItem('date', customFormat);
		}
	}, [history, receptionInfo]);
	//   useEffect(() => {
	//     if (receptionLogin1.receptionInfo) {
	//       console.log("222");
	//       history("/reception/myappointments");
	//     }
	//   }, [history, receptionLogin1.receptionInfo]);
	const submitHandler = (e) => {
		if (OTP.length < 6) {
			alert('OTP cannot be empty');
		} else {
			console.log('line128');
			//role based authentication
			let temp = username.slice(0, 3);
			if (temp == 'DOC') {
				console.log('doc login');
				e.preventDefault();
				dispatch(
					verifyCode(username.slice(3, username.length + 1), OTP, clinicName)
				);
			} else if (temp == 'REC') {
				e.preventDefault();
				dispatch(
					verifyCodeReception(
						username.slice(3, username.length + 1),
						OTP,
						clinicName
					)
				);
			}
		}
	};
	const [timerRunning, setTimerRunning] = useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	useEffect(() => {
		if (doctorLoginerror && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(verifyCodeDoctorReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [doctorLoginsuccess, dispatch]);
	const toggleShowInfoPopupDocCode = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(verifyCodeDoctorReset());
	};
	useEffect(() => {
		if (doctorVerify?.error && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(loginDoctorReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [doctorLoginsuccess, dispatch]);
	const toggleShowInfoPopupDoc = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(verifyCodeDoctorReset());
	};

	useEffect(() => {
		if (receptionVerify?.error && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(verifyCodeReceptionReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [receptionLoginsuccess, dispatch]);
	const toggleShowInfoPopupRecCode = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(verifyCodeReceptionReset());
	};
	useEffect(() => {
		if (receptionLoginerror && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(loginReceptionReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [receptionLoginsuccess, dispatch]);
	const toggleShowInfoPopupRec = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(loginReceptionReset());
	};

	const handleResend = () => {
		let temp = username.slice(0, 3);
		if (temp == 'DOC') {
			console.log('yeeee');
			//dispatch(resendOtp(username));
			dispatch(doctorLogin1(username, password, isMobile, clinicName));
		} else if (temp == 'REC') {
			//dispatch(resendOtpReception(username));
			dispatch(receptionLogin(username, password, isMobile, clinicName));
		}
	};

	const doctorLoginDetails = useSelector((state) => state.doctorLogin);
	const receptionLoginDetails = useSelector((state) => state.receptionLogin);

	const [generateClicked, setGenerateClicked] = useState(false);

	useEffect(() => {
		if (generateClicked) {
			if (doctorLoginDetails?.error || receptionLoginDetails?.error) {
				alert('Wrong credentials for username and password');
				setVerifyOTP('d-none');
			} else {
				setVerifyOTP('d-block');
			}
		}
	}, [doctorLoginDetails?.loading, receptionLoginDetails?.loading]);
	const generateOTP = () => {
		setGenerateClicked(true);
		if (username == '') {
			alert('Username cannot be empty');
		} else if (password == '') {
			alert('Password cannot be empty');
		} else {
			let temp = username.slice(0, 3);
			if (temp == 'DOC') {
				setGenerateOTPBtn('btn btn-success mt-3 mb-3 ml-3');
				console.log(username.slice(3, username.length + 1));
				dispatch(doctorLogin1(username, password, isMobile, clinicName));
			} else if (temp == 'REC') {
				console.log(username);

				setGenerateOTPBtn('btn btn-success mt-3 mb-3 ml-3');
				console.log(username.slice(3, username.length + 1));
				dispatch(receptionLogin(username, password, isMobile, clinicName));
			} else {
				alert('Wrong credentials for username and password');
			}
		}
	};
	const [mybtn, setMyBtn] = useState('btn btn-blue');

	const handleThisForgot = () => {
		setMyBtn('btn btn-green');
		history(`/clinic/bhuvanshomeopathy/verifymail`);
	};

	useEffect(() => {
		dispatch(loginDoctorReset());
		dispatch(loginReceptionReset());
	}, []);

	return (
		<div className='pageSize'>
			{doctorLoginerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopupDoc}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{doctorLoginerror}
				</Message>
			)}

			{doctorVerify?.error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopupDocCode}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{doctorVerify?.error}
				</Message>
			)}

			{receptionLoginerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopupRec}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{receptionLoginerror}
				</Message>
			)}

			{receptionVerify?.error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopupRecCode}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{receptionVerify?.error}
				</Message>
			)}

			<div className='row row-content'>
				<div className='col-12 col-md-4 offset-md-4 loginForm'>
					<h2 className='loginHeader'>Login</h2>
					<form
						className='form-group'
						novalidate
						id='user-form'
						onSubmit={submitHandler}>
						<div className='form-group'>
							<label for='username' className='col-md-12 col-form-label'>
								<b>Username</b>{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='col-md-12'>
								<input
									type='text'
									className='form-control'
									id='username'
									name='username'
									placeholder='Enter your username'
									required
									onChange={(e) => setusername(e.target.value)}
									maxLength={13}
								/>
							</div>
						</div>
						<div className='form-group'>
							<label for='password' className='col-md-12 col-form-label'>
								<b>Password</b>{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='col-md-12 flex321'>
								<input
									className='form-control'
									id='password'
									name='password'
									placeholder='••••••••'
									required
									onChange={(e) => setPassword(e.target.value)}
									type={show ? 'text' : 'password'}
								/>
								<button type='button' className='close' onClick={handleClick}>
									{show == 1 ? (
										<i class='fa fa-eye ppppp' aria-hidden='true'></i>
									) : (
										<i class='fa fa-eye-slash ppppp' aria-hidden='true'></i>
									)}
								</button>
							</div>
						</div>

						{/* <div className="form-group row">
							<label for="sendOtpTo" className="col-md-4 form-check-label">
								<b>Send OTP to:</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<div className="col-md-4">
								<input
									className="form-check-input"
									type="radio"
									name="sendOtpTo"
									id="radio1"
									value="option1"
									onChange={() => setIsMobile(true)}
									checked={isMobile}
								/>
								<label className="form-check-label" for="radio1">
									Mobile Number
								</label>
							</div>
							<div className="col-md-3">
								<input
									className="form-check-input"
									type="radio"
									name="sendOtpTo"
									id="radio2"
									value="option2"
									checked={!isMobile}
									onChange={() => setIsMobile(false)}
								/>
								<label className="form-check-label" for="radio2">
									Email
								</label>
							</div>
						</div> */}
						<div className='form-group row'>
							<button
								type='button'
								onClick={() => generateOTP()}
								className={generateOTPBtn}
								disabled={doctorLoginsuccess || receptionLoginsuccess}>
								<b>Generate OTP</b>
							</button>
							<button
								type='button'
								onClick={() => handleThisForgot()}
								className='btn btn-outline-primary mt-3 mb-3 offset-4 col-4'>
								Forgot Password ?
							</button>
						</div>
						<div className={verifyOTP}>
							<div className='form-group'>
								<OTPInput
									className='offset-md-2'
									value={OTP}
									onChange={setOTP}
									autoFocus
									OTPLength={6}
									otpType='number'
									disabled={false}
								/>
								<ResendOTP
									renderButton={renderButton}
									renderTime={renderTime}
									onResendClick={handleResend}
								/>
							</div>
							<div className='form-group'>
								<button
									type='submit'
									className='btn btn-success mt-2 offset-2 col-8'>
									<b>Verify OTP</b>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AdminLoginScreens;
