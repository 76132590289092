/** @format */

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const servicesData = [
	{
		title: 'Kidney Stones',
		description:
			'Treatment for the formation of small, hard deposits in the kidneys, which can cause severe pain and discomfort.',
		imagePath: '/kidney_stones.jpg',
	},
	{
		title: 'Sinus, Allergy, Asthma',
		description:
			'Remedies for sinus infections, allergies, and asthma, focusing on reducing symptoms like congestion and difficulty breathing.',
		imagePath: '/sinus_allergy_asthma.jpg',
	},
	{
		title: 'Back Pain and Sciatica',
		description:
			'Relief for chronic back pain and sciatica, aiming to improve mobility and reduce discomfort.',
		imagePath: '/back_pain_sciatica.png',
	},
	{
		title: 'Stomach Ache and Acidity',
		description:
			'The treatment aims to alleviate pain and discomfort in the abdominal area while managing excessive stomach acid levels, addressing issues like indigestion, heartburn, and acid reflux to provide holistic relief and improve digestive health.',
		imagePath: '/stomachache_and_acidity.jpg',
	},
	{
		title: 'Psoriasis, Skin Problems',
		description:
			'Solutions for skin conditions like psoriasis, which involve inflammation and scaling of the skin.',
		imagePath: '/psoriasis_skin_problems.jpg',
	},
	{
		title: 'Hair Fall, Dandruff',
		description:
			'Treatments targeting hair loss and dandruff, focusing on improving scalp health and hair strength.',
		imagePath: '/hair_fall_dandruff.jpg',
	},
	{
		title: 'Headache, Migraine',
		description:
			'Approaches to alleviate headaches and migraines, focusing on reducing frequency and severity.',
		imagePath: '/headache_migraine.jpg',
	},
	{
		title: 'Diabetes, BP (Blood Pressure), Sugar',
		description:
			'Management of diabetes and high blood pressure to maintain healthy blood sugar and pressure levels.',
		imagePath: '/diabetes_bp_sugar.jpg',
	},
	{
		title: 'Thyroid',
		description:
			'Treatment options for thyroid-related issues, aiming to balance hormone levels and improve metabolism.',
		imagePath: '/thyroid.jpg',
	},
	{
		title: 'Joint Pain, Arthritis',
		description:
			'Remedies for joint pain and arthritis, focusing on reducing inflammation and improving joint function.',
		imagePath: '/joint_pain_arthritis.jpg',
	},
	{
		title: 'Obesity',
		description:
			'Weight management strategies to help reduce obesity and associated health risks.',
		imagePath: '/obesity.jpg',
	},
];

const Services = () => {
	return (
		<section className='services-section'>
			<h2 className='mb-5'>Explore Our Services</h2>
			<Row className='justify-content-center m-3'>
				{servicesData.map((service, index) => (
					<Col md={3} className='mb-4' key={index}>
						<Card className='service-card'>
							<Card.Img
								variant='top'
								src={service.imagePath}
								alt={service.title}
							/>
							<Card.Body>
								<Card.Title>{service.title}</Card.Title>
								<Card.Text>{service.description}</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</section>
	);
};

export default Services;
