/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Meta from '../../../Components/Meta';
import Message from '../../../Components/Message';
import Form from 'react-bootstrap/Form';
import { Card, Button } from 'react-bootstrap';
import { Col, Row, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
	createSupplier,
	getSuppliers,
	getInventoryItemsForBilling,
	newPurchaseFromSupplier,
} from '../../../redux/inventoryRedux/inventoryApiCalls';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router-dom';
import {
	getInventoryCategory,
	createInventoryItem,
	editInventoryItem,
} from '../../../redux/inventoryRedux/inventoryApiCalls';
import ButtonLoader from '../../../Components/ButtonLoader';
import { createInventoryCategory } from '../../../redux/inventoryRedux/inventoryApiCalls';

const PurchaseInventoryScreens = () => {
	const { clinicName } = useParams();

	const dispatch = useDispatch();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		setName('');
		setEmail('');
		setContactNo('');
		setAddress('');
	};

	useEffect(() => {
		dispatch(getSuppliers(clinicName));
	}, []);

	const { getsuppliers } = useSelector((state) => state.getSuppliers);

	console.log(getsuppliers);

	const createsupplier = useSelector((state) => state.createSupplier);
	const createsupplierLoading = createsupplier?.loading;

	useEffect(() => {
		dispatch(getSuppliers(clinicName));
	}, [createsupplierLoading]);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [ContactNo, setContactNo] = useState('');
	const [address, setAddress] = useState('');

	const [supplier, setSupplier] = useState('Choose Supplier');
	const [supplierId, setSupplierId] = useState('');

	const handleSupplierSelect = (eventKey) => {
		let arr = eventKey?.split('@@');
		setSupplier(arr[0]);
		setSupplierId(arr[1]);
	};

	const submitNewSupplier = () => {
		if (name === '' || ContactNo === '' || email === '' || address === '') {
			alert('Please fill all the required fields');
		} else {
			dispatch(createSupplier(name, email, ContactNo, address, clinicName));
			setShow(false);
			setName('');
			setEmail('');
			setContactNo('');
			setAddress('');
		}
	};

	useEffect(() => {
		dispatch(getInventoryItemsForBilling(clinicName));
	}, []);

	const { getInventoryItemsForbilling } = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	const [show1, setShow1] = useState(false);

	const handleClose1 = () => setShow1(false);
	const handleShow1 = () => {
		setShow1(true);
		setProductName('Choose Product');
		setProductId('');
		setCategoryId('');
		setQuantityPurchased('');
		setCost('');
		setExpiryDate('');
		setBrand('');
		setUnits('');
	};

	const [productName, setProductName] = useState('Choose Product');
	const [productId, setProductId] = useState('');
	const [categoryId, setCategoryId] = useState('');
	const [quantityPurchased, setQuantityPurchased] = useState('');
	const [cost, setCost] = useState('');
	const [expiryDate, setExpiryDate] = useState('');
	const [brand, setBrand] = useState('');
	const [units, setUnits] = useState('');

	const [newPurchaseItems, setNewPurchaseItems] = useState([]);

	const [editClicked, setEditClicked] = useState(false);
	const [editIndex, setEditIndex] = useState(0);

	const AddNewPurchase = () => {
		const purchasedItems = [...newPurchaseItems];
		purchasedItems?.push({
			name: productName,
			id: productId,
			categoryId: categoryId,
			quantityPurchased: quantityPurchased,
			cost: cost,
			expiryDate: expiryDate,
			brand: brand,
			unit: units,
		});
		setNewPurchaseItems(purchasedItems);
		setShow1(false);
		setProductName('Choose Product');
		setProductId('');
		setCategoryId('');
		setQuantityPurchased('');
		setCost('');
		setExpiryDate('');
		setBrand('');
		setUnits('');
	};

	const updatePurchase = (index) => {
		setShow1(true);
		const purchasedItems = [...newPurchaseItems];
		setProductName(purchasedItems[index]?.name);
		setProductId(purchasedItems[index]?.id);
		setCategoryId(purchasedItems[index]?.categoryId);
		setQuantityPurchased(purchasedItems[index]?.quantityPurchased);
		setCost(purchasedItems[index]?.cost);
		setExpiryDate(purchasedItems[index]?.expiryDate);
		setBrand(purchasedItems[index]?.brand);
		setUnits(purchasedItems[index]?.unit);
		setEditClicked(true);
		setEditIndex(index);
	};

	const updatePurchaseSubmit = () => {
		const purchasedItems = [...newPurchaseItems];
		const updatedPurchasedItem = {
			name: productName,
			id: productId,
			categoryId: categoryId,
			quantityPurchased: quantityPurchased,
			cost: cost,
			expiryDate: expiryDate,
			brand: brand,
			unit: units,
		};
		purchasedItems[editIndex] = updatedPurchasedItem;
		setNewPurchaseItems(purchasedItems);
		setShow1(false);
		setProductName('Choose Product');
		setProductId('');
		setCategoryId('');
		setQuantityPurchased('');
		setCost('');
		setExpiryDate('');
		setBrand('');
		setUnits('');
		setEditClicked(false);
	};

	const deletePurchase = (index) => {
		const purchasedItems = [...newPurchaseItems];
		purchasedItems?.splice(index, 1);
		setNewPurchaseItems(purchasedItems);
	};

	const getTodayDateInNumberFormat = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero indexed, so we add 1
		const day = String(today.getDate()).padStart(2, '0');

		return Number(`${year}${month}${day}`);
	};

	const submitNewPurchase = () => {
		const purchasedItems = [...newPurchaseItems];
		if (supplier === 'Choose Supplier') {
			alert('Please select a supplier');
		} else {
			console.log(purchasedItems);
			const products = purchasedItems.map((item) => {
				const formattedExpiryDate = item.expiryDate.replace(/-/g, '');
				return {
					ProductID: item.id,
					productName: item.name,
					QuantityPurchased: item.quantityPurchased,
					costPrice: item.cost,
					expiryDate: Number(formattedExpiryDate), // Convert to number
					brand: {
						name: item.brand,
					},
				};
			});
			console.log(products);

			let stockBatch =
				getInventoryItemsForbilling?.data?.data?.stockBatches || [];

			stockBatch = purchasedItems.map((item, index) => {
				const formattedExpiryDate = item.expiryDate.replace(/-/g, '');
				return {
					batchId: `batch${index + 1}`,
					expiryDate: Number(formattedExpiryDate), // Convert to number
					quantity: item.quantityPurchased,
					costPrice: item.cost,
				};
			});

			const inventoryMap = new Map();

			purchasedItems.forEach((item) => {
				const matchingProduct = getInventoryItemsForbilling?.data?.data?.find(
					(product) => product._id === item.id
				);

				if (matchingProduct) {
					const formattedExpiryDate = item.expiryDate.replace(/-/g, '');

					// Create a copy of stockBatches to make it extensible
					const updatedStockBatches = [
						...(matchingProduct?.stockBatches || []),
					];

					let productEntry = {
						productId: matchingProduct._id,
						updatedstockBatches: updatedStockBatches,
						quantity: matchingProduct?.Totalquantity,
						categoryId: matchingProduct.categoryId,
						amount: 0,
						batchCount: updatedStockBatches.length, // Initialize batch count
					};

					// Increment batch count
					productEntry.batchCount += 1;

					const newStockBatch = {
						batchId: `batch${productEntry.batchCount}`,
						expiryDate: Number(formattedExpiryDate), // Convert to number
						quantityLeft: Number(item.quantityPurchased),
						cost_price: Number(item.cost),
						supplierName: supplier,
						supplierId: supplierId,
						receivedDate: getTodayDateInNumberFormat(),
						brand: {
							name: item.brand,
						},
					};

					// Append new stock batch
					productEntry.updatedstockBatches.push(newStockBatch);

					// Update quantity and amount
					productEntry.quantity = newStockBatch.quantityLeft;
					productEntry.amount +=
						newStockBatch.quantityLeft * newStockBatch.cost_price;

					// Update the map
					inventoryMap.set(matchingProduct._id, productEntry);
				}
			});

			// Convert map to array
			const inventoryData = Array.from(inventoryMap.values()).map(
				({ batchCount, ...rest }) => rest
			); // Exclude batchCount from the final output

			console.log(inventoryData);

			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
			const day = String(today.getDate()).padStart(2, '0');

			const totalAmount = inventoryData.reduce(
				(total, item) => total + item.amount,
				0
			);

			console.log('Total Amount:', totalAmount);

			console.log(inventoryData);

			dispatch(
				newPurchaseFromSupplier(
					products,
					Number(`${year}${month}${day}`),
					totalAmount,
					supplier,
					supplierId,
					inventoryData,
					clinicName
				)
			);

			setNewPurchaseItems([]);
			setShow1(false);
			setProductName('Choose Product');
			setProductId('');
			setCategoryId('');
			setQuantityPurchased('');
			setCost('');
			setExpiryDate('');
			setBrand('');
			setUnits('');
			setEditClicked(false);
		}
	};

	const [addInventory, setAddInventory] = useState(false);
	const [nameAddInventory, setNameAddInventory] = useState('');
	const [categoryAddInventory, setCategoryAddInventory] =
		useState('Choose Category');
	const [categoryIdAddInventory, setCategoryIdAddInventory] = useState('');
	const [sellingPriceAddInventory, setSellingPriceAddInventory] = useState('');
	const [descriptionAddInventory, setDescriptionAddInventory] = useState('');
	const [unitsAddInventory, setUnitsAddInventory] = useState('');
	const [minStockLevelAddInventory, setMinStockLevelAddInventory] =
		useState('');
	const [inventoryIdAddInventory, setInventoryIdAddInventory] = useState('');
	const [statusAddInventory, setStatusAddInventory] = useState('');

	useEffect(() => {
		dispatch(getInventoryCategory(clinicName));
		dispatch(getInventoryItemsForBilling(clinicName));
	}, []);

	const { getInventorycategory } = useSelector(
		(state) => state.getInventoryCategory
	);

	console.log(getInventoryItemsForbilling);

	console.log(getInventorycategory);

	const submitInventoryHandler = () => {
		if (
			nameAddInventory === '' ||
			categoryAddInventory === 'Choose Category' ||
			sellingPriceAddInventory === '' ||
			unitsAddInventory === '' ||
			minStockLevelAddInventory === ''
		) {
			alert('Please fill all the required fields');
		} else {
			dispatch(
				createInventoryItem(
					nameAddInventory,
					descriptionAddInventory,
					unitsAddInventory,
					sellingPriceAddInventory,
					categoryAddInventory,
					categoryIdAddInventory,
					minStockLevelAddInventory,
					clinicName
				)
			);
			setNameAddInventory('');
			setDescriptionAddInventory('');
			setUnitsAddInventory('');
			setSellingPriceAddInventory('');
			setCategoryAddInventory('Choose Category');
			setCategoryIdAddInventory('');
			setMinStockLevelAddInventory('');
			setStatusAddInventory('');
			setInventoryIdAddInventory('');
			// setShow(false);
		}
	};

	const addInventoryData = useSelector((state) => state.createInventoryItem);

	useEffect(() => {
		dispatch(getInventoryItemsForBilling(clinicName));
	}, [addInventoryData?.loading]);

	const [addCategoryItem, setAddCategoryItem] = useState(false);
	const [categoryItem, setCategoryItem] = useState('');
	const [descriptionItem, setDescriptionItem] = useState('');

	const submitCategoryHandler = (e) => {
		// e.preventDefault();
		console.log(categoryItem);

		dispatch(
			createInventoryCategory(categoryItem, descriptionItem, clinicName)
		);
		// setShow(false);
		setCategoryItem('');
		setDescriptionItem('');
	};

	const addCategoryData = useSelector((state) => state.createInventoryCategory);

	useEffect(() => {
		dispatch(getInventoryCategory(clinicName));
	}, [addCategoryData?.loading]);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item
					href={`/clinic/bhuvanshomeopathy/doctor/purchaseinventory`}>
					New Purchase
				</Breadcrumb.Item>
				<Breadcrumb.Item
					href={`/clinic/bhuvanshomeopathy/doctor/pastpurchaseinventory`}>
					Past Purchases
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center mt-3'>Purchase Inventory</h1>
			<Container className='header-center mt-5 mb-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Choose Supplier{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={supplier}
							onSelect={handleSupplierSelect}>
							{getsuppliers?.data?.data.map((supplier, index) => (
								<Dropdown.Item eventKey={supplier?.name + '@@' + supplier?._id}>
									{supplier?.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<button className='btn btn-secondary' onClick={handleShow}>
							Add New Supplier Details
						</button>
					</Col>
				</Row>
			</Container>

			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Add New Supplier</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-group' noValidate='true'>
						<Container className='mt-3'>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Name: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Contact Number: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='number'
										required='required'
										value={ContactNo}
										onChange={(e) => setContactNo(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Email: </b>
										{/* <span className="text-danger">
											<b>*</b>
										</span> */}
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										// required="required"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Address: </b>
										{/* <span className="text-danger">
											<b>*</b>
										</span> */}
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<textarea
										className='form-control'
										id='speciality'
										type='text'
										// required="required"
										value={address}
										onChange={(e) => setAddress(e.target.value)}></textarea>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
					<Button variant='primary' onClick={submitNewSupplier}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>

			<Container fluid className='mt-5'>
				<Row className='justify-content-center'>
					<Card className='w-100 ml-3 mr-3'>
						<Card.Body>
							<div className='d-flex justify-content-between align-items-center mb-2'>
								<Card.Title className='mb-0'>Purchase List</Card.Title>
								<div className='ml-auto'>
									<Button
										variant='primary'
										onClick={handleShow1}
										className='mr-2'>
										Add New Purchase
									</Button>
								</div>
							</div>

							<hr style={{ margin: '0 -1.25rem 1rem -1.25rem' }} />
							<Table
								bordered
								className='custom-table'
								style={{ fontSize: '110%' }}>
								<thead>
									<tr>
										<th scope='col'>S. No</th>
										<th scope='col'>Product Name</th>
										<th scope='col'>Quantity</th>
										<th scope='col'>Cost</th>
										<th scope='col'>Expiry Date</th>
										<th scope='col'>Brand</th>
										<th scope='col'>Action</th>
									</tr>
								</thead>
								<tbody>
									{newPurchaseItems?.length > 0 &&
										newPurchaseItems?.map((item, index) => (
											<tr
												className={
													item?.status === 'discontinued'
														? 'bg-light-danger text-light'
														: ''
												}>
												<td>{index + 1}</td>
												<td>{item?.name}</td>
												<td>{item?.quantityPurchased}</td>
												<td>{item?.cost}</td>
												<td>{item?.expiryDate}</td>
												<td>{item?.brand}</td>

												<td>
													<button
														className='btn btn-success'
														onClick={() => updatePurchase(index)}>
														<i className='fa fa-edit'></i>
													</button>{' '}
													<button
														className='btn btn-danger'
														onClick={() => deletePurchase(index)}>
														<i className='fa fa-trash'></i>
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</Table>
							{newPurchaseItems?.length === 0 && (
								<h5 className='text-danger text-center'>
									Add items to view the list
								</h5>
							)}
							<Container fluid className='mt-3'>
								<Row className='justify-content-end'>
									<button
										className='btn btn-success'
										onClick={submitNewPurchase}>
										Submit Purchase
									</button>
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</Row>
			</Container>

			<Modal show={show1} onHide={handleClose1} size='lg'>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<Row>
						<Col md='1'>
							{addCategoryItem ? (
								<button
									className='btn btn-outline-primary btn-sm'
									onClick={() => setAddCategoryItem(!addCategoryItem)}
									title='close'>
									<i className='fa fa-minus-square'></i>
								</button>
							) : (
								<button
									className='btn btn-outline-primary btn-sm'
									onClick={() => setAddCategoryItem(!addCategoryItem)}
									title='open'>
									<i className='fa fa-plus-square'></i>
								</button>
							)}
						</Col>
						<Col>
							<h4>Add New Category</h4>
						</Col>
					</Row>
					{addCategoryItem && (
						<Form className='form-group' noValidate='true'>
							<Container className='header-center mt-3'>
								<Row className='justify-content-center'>
									<Col md='3' className='text-center mt-1'>
										<label htmlFor='name'>
											<b>Category Name: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className='text-center mt-1'>
										<input
											className='form-control'
											id='speciality'
											type='text'
											required='required'
											value={categoryItem}
											placeholder='Enter Tablet, Syrup ...'
											onChange={(e) => setCategoryItem(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='3' className='text-center mt-1'>
										<label htmlFor='name'>
											<b>Description: </b>
										</label>
									</Col>
									<Col md='6' className='text-center mt-1'>
										<textarea
											className='form-control'
											id='speciality'
											type='text'
											required='required'
											value={descriptionItem}
											onChange={(e) =>
												setDescriptionItem(e.target.value)
											}></textarea>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<Button variant='primary' onClick={submitCategoryHandler}>
											Save Category{' '}
											{addCategoryData?.loading && <ButtonLoader />}
										</Button>
									</Col>
								</Row>
							</Container>
						</Form>
					)}
					<hr />
					<Row>
						<Col md='1'>
							{addInventory ? (
								<button
									className='btn btn-outline-primary btn-sm'
									onClick={() => setAddInventory(!addInventory)}
									title='close'>
									<i className='fa fa-minus-square'></i>
								</button>
							) : (
								<button
									className='btn btn-outline-primary btn-sm'
									onClick={() => setAddInventory(!addInventory)}
									title='open'>
									<i className='fa fa-plus-square'></i>
								</button>
							)}
						</Col>
						<Col>
							<h4>Add New Inventory Item</h4>
						</Col>
					</Row>
					{addInventory && (
						<Form className='form-group' noValidate='true'>
							<Container className='mt-3'>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Product name: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<input
											className='form-control'
											id='speciality'
											type='text'
											required='required'
											placeholder='Enter Dolo 650MG, Sinarest ...'
											value={nameAddInventory}
											onChange={(e) => setNameAddInventory(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Units of Measurement: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<input
											className='form-control'
											id='speciality'
											type='text'
											required='required'
											value={unitsAddInventory}
											placeholder='Enter Sheets, Bottles ...'
											onChange={(e) => setUnitsAddInventory(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Description: </b>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<textarea
											className='form-control'
											id='speciality'
											type='text'
											required='required'
											value={descriptionAddInventory}
											onChange={(e) =>
												setDescriptionAddInventory(e.target.value)
											}></textarea>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Category: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<DropdownButton
											key='down-centered'
											id={`dropdown-button-drop-down-centered`}
											drop='down-centered'
											variant='success'
											title={categoryAddInventory}
											onSelect={(eventKey) => {
												let arr = eventKey?.split('@@');
												setCategoryAddInventory(arr[0]);
												setCategoryIdAddInventory(arr[1]);
											}}>
											{getInventorycategory?.data?.data?.map((item) => {
												if (item?.name !== 'hospital') {
													return (
														<Dropdown.Item
															eventKey={item?.name + '@@' + item?._id}>
															{item?.name}
														</Dropdown.Item>
													);
												}
												return null;
											})}
										</DropdownButton>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Selling Price: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<input
											className='form-control'
											id='speciality'
											type='number'
											required='required'
											value={sellingPriceAddInventory}
											onChange={(e) =>
												setSellingPriceAddInventory(e.target.value)
											}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<label htmlFor='name'>
											<b>Minimum Stock Level: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
									</Col>
									<Col md='6' className=' mt-1'>
										<input
											className='form-control'
											id='speciality'
											type='number'
											required='required'
											value={minStockLevelAddInventory}
											onChange={(e) =>
												setMinStockLevelAddInventory(e.target.value)
											}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center'>
									<Col md='4' className=' mt-1'>
										<Button variant='primary' onClick={submitInventoryHandler}>
											Save Item {addInventoryData?.loading && <ButtonLoader />}
										</Button>
									</Col>
								</Row>
							</Container>
						</Form>
					)}
					<hr />
					<h4>Add New Purchase</h4>
					<Form className='form-group' noValidate='true'>
						<Container className='mt-3'>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Product Name: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={productName}
										onSelect={(eventKey) => {
											let arr = eventKey?.split('@@');
											setProductId(arr[0]);
											setProductName(arr[1]);
											setCategoryId(arr[2]);
											setUnits(arr[3]);
										}}>
										{' '}
										<div className='dropScroll'>
											{getInventoryItemsForbilling?.data?.data.map(
												(item, index) => (
													<Dropdown.Item
														eventKey={
															item?._id +
															'@@' +
															item?.name +
															'@@' +
															item?.categoryId +
															'@@' +
															item?.unit
														}>
														{item?.name}
													</Dropdown.Item>
												)
											)}
										</div>
									</DropdownButton>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>
											{units === '' ? 'Quantity:' : `Quantity (${units}):`}{' '}
										</b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='number'
										required='required'
										value={quantityPurchased}
										onChange={(e) => setQuantityPurchased(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>{units === '' ? 'Cost:' : `Cost per ${units}:`} </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={cost}
										onChange={(e) => setCost(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Expiry Date: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='date'
										required='required'
										value={expiryDate}
										onChange={(e) => setExpiryDate(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Brand: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={brand}
										onChange={(e) => setBrand(e.target.value)}
									/>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose1}>
						Close
					</Button>
					<Button
						variant='primary'
						onClick={editClicked ? updatePurchaseSubmit : AddNewPurchase}>
						Add to list
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PurchaseInventoryScreens;
