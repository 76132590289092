/** @format */

import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	FormCheck,
	Modal,
	Container,
	Row,
	Col,
	Card,
} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faTrash,
	faPlus,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
	GetPrescriptionTemplateNames,
	GetPrescriptionTemplate,
} from '../../../redux/doctorRedux/doctorApiCalls';
import { Pagination } from 'react-bootstrap';
import { createCategory } from '../../../redux/userApiCalls';
import { getUserDataById } from '../../../redux/prescriptionRedux/prescriptionApiCalls';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
	getTemplate1,
	getTemplateNames, //
} from '../../../redux/doctorRedux/doctorApiCalls';
import {
	addVitalInDoctor,
	getVitalInDoctor,
} from '../../../redux/adminRedux/adminApiCalls';
import {
	getCategoriesVitals, //
} from '../../../redux/prescriptionRedux/prescriptionApiCalls';
import {
	createPrescription,
	onSpotPrescription, //
} from '../../../redux/prescriptionRedux/prescriptionApiCalls';
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from '../../../redux/UINavigationRedux/StartPrescriptionToStartHistory';
import { createPrescriptionReset } from '../../../redux/prescriptionRedux/createPrescription';
import Meta from '../../../Components/Meta';

const DynamicRow = ({ index, values, handleChange, handleDelete }) => {
	const [activeBox, setActiveBox] = useState(
		values[`dosage-${index}`]?.includes('O@@') ? false : true
	);
	const [Time, setTime] = useState(
		values[`dosage-${index}`]?.includes('B/F')
			? 'B/F'
			: values[`dosage-${index}`]?.includes('A/F')
			? 'A/F'
			: ''
	);
	const [M, setM] = useState(
		values[`dosage-${index}`]?.includes('M,') ? 'M' : ''
	);
	const [A, setA] = useState(
		values[`dosage-${index}`]?.includes('A,') ? 'A' : ''
	);
	const [N, setN] = useState(
		values[`dosage-${index}`]?.includes('N') ? 'N' : ''
	);
	const [quantity, setQuantity] = useState('');
	const handleFreq = (value) => {
		if (value === 'M') {
			if (M === 'M') setM('');
			else setM('M');
		} else if (value === 'A') {
			if (A === 'A') setA('');
			else setA('A');
		} else if (value === 'N') {
			if (N === 'N') setN('');
			else setN('N');
		}
	};
	useEffect(() => {
		if (Time !== '' && (M === 'M' || A === 'A' || N === 'N')) {
			handleChange(`dosage-${index}`, Time + ',' + M + ',' + A + ',' + N);
			debugger;
			let durationFromMedication =
				duration === 'Days'
					? 1
					: duration === 'Weeks'
					? 7
					: duration === 'Months'
					? 30
					: 365;
			let frequencyFromMedication = M === 'M' ? 1 : 0;
			frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
			frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
			frequencyFromMedication =
				frequencyFromMedication *
				Number(values[`duration-${index}`]?.split(' ')[0]) *
				durationFromMedication;
			setQuantity(frequencyFromMedication);
		} else {
			setQuantity(0);
		}
	}, [Time, M, A, N]);

	const activeBoxSetting = () => {
		setActiveBox(!activeBox);
		handleChange(`dosage-${index}`, '');
		setTime('');
		setM('');
		setA('');
		setN('');
	};

	useEffect(() => {
		if (!values[`duration-${index}`]) {
			handleChange(`duration-${index}`, '1 ' + duration + ' ' + repeat);
		}
	}, []);

	const [duration, setDuration] = useState(
		!values[`duration-${index}`]
			? 'Days'
			: values[`duration-${index}`]?.split(' ')[1]
	);
	const [repeat, setRepeat] = useState(
		!values[`duration-${index}`]
			? 'Daily'
			: values[`duration-${index}`]?.split(' ')[2]
	);
	const changeRepeat = (eventKey) => {
		setRepeat(eventKey);
		handleChange(`duration-${index}`, '1 ' + duration + ' ' + eventKey);
	};
	const changeDur = (eventKey) => {
		setDuration(eventKey);
		handleChange(`duration-${index}`, '1 ' + eventKey + ' ' + repeat);
		let durationFromMedication =
			eventKey === 'Days'
				? 1
				: eventKey === 'Weeks'
				? 7
				: eventKey === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * 1;
		setQuantity(frequencyFromMedication);
	};
	const incr = (quan) => {
		let quan1 = Number(quan);
		quan1++;
		console.log(quan, 'hi');
		handleChange(
			`duration-${index}`,
			quan1.toString() + ' ' + duration + ' ' + repeat
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan1;
		setQuantity(frequencyFromMedication);
	};
	const decr = (quan) => {
		quan = Number(quan);
		quan--;
		handleChange(
			`duration-${index}`,
			quan.toString() + ' ' + duration + ' Daily'
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan;
		setQuantity(frequencyFromMedication);
	};

	return (
		<Card style={{ width: '22rem', marginLeft: '1%', marginTop: '1%' }}>
			<Card.Body>
				<Card.Text>
					<Row className='justify-content-end'>
						<button
							className='btn btn-outline-danger'
							onClick={() => handleDelete(index)}>
							<i className='fa fa-trash'></i>
						</button>
					</Row>
					<Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								name={`name-${index}`}
								value={values[`name-${index}`]}
								onChange={(e) => handleChange(`name-${index}`, e.target.value)}
								placeholder='Enter Medicine'
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col>
							<button
								className='btn btn-primary'
								onClick={() => activeBoxSetting()}>
								<i class='fas fa-sync-alt'></i>
							</button>
							{activeBox ? (
								<>
									<ButtonGroup aria-label='Basic example'>
										<Button
											onClick={() => setTime('B/F')}
											// variant="secondary"
											variant={Time === 'B/F' ? 'success' : 'secondary'}>
											B/F
										</Button>
										<Button
											onClick={() => setTime('A/F')}
											variant={Time === 'A/F' ? 'success' : 'secondary'}>
											A/F
										</Button>
									</ButtonGroup>
									<Button
										onClick={() => handleFreq('M')}
										variant={M === 'M' ? 'success' : 'outline-success'}>
										M
									</Button>
									<Button
										onClick={() => handleFreq('A')}
										variant={A === 'A' ? 'success' : 'outline-success'}>
										A
									</Button>
									<Button
										onClick={() => handleFreq('N')}
										variant={N === 'N' ? 'success' : 'outline-success'}>
										N
									</Button>
									<DropdownButton
										className='mt-2'
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={repeat}
										onSelect={changeRepeat}>
										<Dropdown.Item eventKey={"Doesn't Repeat"}>
											{"Doesn't Repeat"}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Daily'}>{'Daily'}</Dropdown.Item>
										<Dropdown.Item eventKey={'Weekly'}>
											{'Weekly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Monthly'}>
											{'Monthly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Yearly'}>
											{'Yearly'}
										</Dropdown.Item>
									</DropdownButton>
								</>
							) : (
								<>
									<input
										type='text'
										name={`dosage-${index}`}
										value={values[`dosage-${index}`].split('@@')[1]}
										onChange={(e) =>
											handleChange(`dosage-${index}`, 'O@@' + e.target.value)
										}
									/>
								</>
							)}
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									decr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-minus'></i>
							</button>
						</Col>
						<Col md='4'>
							<input
								className='form-control'
								type='text'
								name={`duration-${index}`}
								value={values[`duration-${index}`]?.split(' ')[0]}
								onChange={(e) =>
									handleChange(
										`duration-${index}`,
										e.target.value + ' ' + duration + ' ' + repeat
									)
								}
							/>
						</Col>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									incr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-plus'></i>
							</button>
						</Col>
						<Col md='4'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={duration}
								onSelect={changeDur}>
								<Dropdown.Item eventKey={'Days'}>{'Days'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Weeks'}>{'Weeks'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Months'}>{'Months'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Years'}>{'Years'}</Dropdown.Item>
							</DropdownButton>
						</Col>
					</Row>
					{/* <Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								type='text'
								value={`Quantity: ${quantity}`}
							/>
						</Col>
					</Row> */}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

const StartPrescriptionScreens = () => {
	const { clinicName } = useParams();
	console.log(clinicName);
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	console.log(location?.state);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	useEffect(() => {
		dispatch(GetPrescriptionTemplateNames(doctorInfo?.user?._id, clinicName));
		dispatch(getVitalInDoctor(doctorInfo?.user?._id, clinicName));
	}, []);

	const { getPrescriptionTemplateNames } = useSelector(
		(state) => state.GetPrescriptionTemplateNames
	);

	console.log(getPrescriptionTemplateNames);

	const [templateName, setTemplateName] = useState(['Choose Template']);
	const [templateIdx, setTemplateIdx] = useState(['']);
	const [formValues, setFormValues] = useState({}); // State variable to hold form values

	const createCategoryData = useSelector((state) => state.createCategory);
	const createCategorySuccess = createCategoryData.success;
	const createCategoryFailure = createCategoryData.error;
	const createCategoryloading = createCategoryData.loading;

	const createPrescriptionData = useSelector(
		(state) => state.createPrescription
	);
	const createPrescriptionSuccess = createPrescriptionData.success;
	const createPrescriptionError = createCategoryData.error;
	const createPrescriptionLoading = createPrescriptionData.loading;

	const onSpotPrescriptionData = useSelector(
		(state) => state.onSpotPrescription
	);
	const onSpotPrescriptionSuccess = onSpotPrescriptionData.success;
	const onSpotPrescriptionError = createCategoryData.error;
	const onSpotPrescriptionLoading = onSpotPrescriptionData.loading;

	let flag = false;

	const [getTemplateDataEdited, setGetTemplateDataEdited] = useState(false);

	const TemplateHandler = (temp) => {
		let arr = temp?.split('@@');
		debugger;
		if (templateName?.includes(arr[0]) && arr[0] === 'Medication') {
			debugger;
			let index = templateName?.indexOf('Medication');
			alert(`Medication is already selected in ${index + 1} page.`);
		} else {
			let templates = [...templateName];
			templates[activePage - 1] = arr[0];
			let templatesidx = [...templateIdx];
			templatesidx[activePage - 1] = arr[1];
			setTemplateName(templates);
			setTemplateIdx(templatesidx);
			flag = true;
			setGetTemplateDataEdited(false);
			arr[0] !== 'Medication' &&
				dispatch(
					GetPrescriptionTemplate(doctorInfo?.user?._id, arr[2], clinicName)
				);
			let prescriptiontemplates = [...prescritpionTemplate];
			prescriptiontemplates[activePage - 1] =
				doctorInfo?.user?.prescriptionTemplates[arr[2]]?.template;
			setPrescritpionTemplate(prescriptiontemplates);
		}
	};

	const [prescritpionTemplate, setPrescritpionTemplate] = useState(['']);

	console.log(prescritpionTemplate);

	// Handler for input elements
	const handleInputChange = (event, label, index, eleindex) => {
		const templateArray = prescritpionTemplate[activePage - 1];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...prescritpionTemplate];
		templates[activePage - 1] = updatedFormData;

		setPrescritpionTemplate(templates);
	};

	// Handler for checkbox and radio elements
	const handleCheckboxRadioChange = (event, label, option, index, eleindex) => {
		if (event.target.type === 'checkbox') {
			// For checkboxes, toggle the selected option
			const selectedOptions =
				prescritpionTemplate[activePage - 1][eleindex]?.value || [];
			const updatedOptions = event.target.checked
				? [...selectedOptions, option]
				: selectedOptions.filter((item) => item !== option);

			const templateArray = prescritpionTemplate[activePage - 1];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: updatedOptions };
				}
				return element;
			});
			let templates = [...prescritpionTemplate];
			templates[activePage - 1] = updatedFormData;

			setPrescritpionTemplate(templates);
		} else if (event.target.type === 'radio') {
			// For radio buttons, select only the clicked option
			const templateArray = prescritpionTemplate[activePage - 1];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: option };
				}
				return element;
			});
			let templates = [...prescritpionTemplate];
			templates[activePage - 1] = updatedFormData;

			setPrescritpionTemplate(templates);
		}
	};

	const handleMultiselectChange = (selectedOptions, label, index, eleindex) => {
		// Extract labels and values from selectedOptions
		const selectedValues = selectedOptions.map((option) => ({
			label: option.label,
			value: option.value,
		}));

		const templateArray = prescritpionTemplate[activePage - 1];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: selectedValues };
			}
			return element;
		});
		let templates = [...prescritpionTemplate];
		templates[activePage - 1] = updatedFormData;

		setPrescritpionTemplate(templates);
	};

	// Handler for select
	const handleSelectChange = (event, label, index, eleindex) => {
		const templateArray = prescritpionTemplate[activePage - 1];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...prescritpionTemplate];
		templates[activePage - 1] = updatedFormData;

		setPrescritpionTemplate(templates);
	};

	const handleSubmitForm = () => {
		let categories = [];
		categories?.push(
			chooseCategory?.map((option, index) => {
				return option?.value;
			})
		);

		console.log(vitals);
		const filteredVitals = vitals.filter((vital) => vital.vitalValue !== '');

		console.log(categories[0]);
		const hasEmptyStringOrUndefinedInHistoryTemplate =
			prescritpionTemplate.some((item, index) => {
				if (templateName[index] === 'Medication') {
					return false; // Skip this iteration
				} else if (item === '' || item === undefined) {
					return true;
				}
				return false; // Ensure false is returned for all other cases
			});
		if (title === '') {
			alert('Enter the Title');
		} else if (categories[0]?.length <= 0) {
			alert('Enter the categories');
		} else if (hasEmptyStringOrUndefinedInHistoryTemplate) {
			alert('Empty page cannot be submitted');
		} else {
			let extractedValues = [];
			let flag = false;
			Object.keys(values).forEach((key) => {
				let id = key.replace(/-\d+$/, '');

				if (key.includes('dosage')) {
					let index = key.split('-')[1];
					let dosage = values[`dosage-${index}`];
					if (!dosage?.includes('A/F') && !dosage?.includes('B/F')) {
						// flag = true;
					}
				}
			});

			if (flag) {
				alert('Please Select B/F or A/F for the entered medicines.');
			} else {
				Object.keys(values).forEach((key) => {
					let id = key.replace(/-\d+$/, '');

					if (key.includes('name')) {
						let index = key.split('-')[1];
						let name = values[`name-${index}`];
						let dosage = values[`dosage-${index}`];
						let duration = values[`duration-${index}`];

						extractedValues?.push({
							name: name,
							dosage: dosage,
							duration: duration,
						});
					}
				});

				console.log(extractedValues);

				const medicines = extractedValues;
				let updatedMed = editValues;
				for (let i = 0; i < medicines.length; i++) {
					updatedMed.push({
						name: medicines[i].name,
						dosage: medicines[i].dosage,
						duration: medicines[i].duration,
					});
				}

				for (let i = 0; i < templateName?.length; i++) {
					if (templateName[i] === 'Medication') {
						let prestemp = [...prescritpionTemplate];
						prestemp[i] = updatedMed;
						setPrescritpionTemplate(prestemp);
					}
				}
				// let prestemp = [...prescritpionTemplate];
				// prestemp.splice(0, 0, vitals);
				// setPrescritpionTemplate(prestemp);
				console.log('Form submitted:', prescritpionTemplate);
				setShow(true);

				debugger;
				if (location?.state?.bookingId === 'Emergency Booking') {
					dispatch(
						onSpotPrescription(
							filteredVitals,
							categories[0],
							title,
							doctorInfo?.user?._id,
							doctorInfo?.user?.name,
							updatedMed,
							location.state.name,
							location.state.id,
							location?.state?.date,
							location?.state?.userIdx,
							doctorInfo?.user?.opConsultationFee,
							prescritpionTemplate,
							templateName,
							clinicName,
							doctorInfo?.user?.speciality
						)
					);
				} else {
					dispatch(
						createPrescription(
							filteredVitals,
							categories[0],
							title,
							doctorInfo?.user?._id,
							doctorInfo?.user?.name,
							updatedMed,
							location.state.name,
							location.state.id,
							location?.state?.date,
							doctorInfo?.user?.speciality,
							location.state.bookingId,
							location?.state?.userIdx,
							prescritpionTemplate,
							templateName,
							clinicName
						)
					);
				}
			}
		}
	};

	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState([0]);
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		// You can add logic here to fetch data for the selected page
	};
	const handleAddPage = () => {
		let pages = [...totalPages];
		let lastPage = totalPages[totalPages?.length - 1];
		let newPage = lastPage + 1;
		pages?.push(newPage);
		setTotalPages(pages);
		let templatesSelect = [...templateName];
		templatesSelect?.push('Choose Template');
		setTemplateName(templatesSelect);
		let templatesIdxSelect = [...templateIdx];
		templatesIdxSelect?.push('');
		setTemplateIdx(templatesIdxSelect);
		let prescriptionTemplatesSelect = [...prescritpionTemplate];
		prescriptionTemplatesSelect?.push('');
		setPrescritpionTemplate(prescriptionTemplatesSelect);
		let editbuttons = [...editClicked];
		editbuttons?.push(false);
		setEditClicked(editbuttons);
		setActivePage(newPage + 1);
	};

	const allVitals = useSelector((state) => state.getVitals);

	console.log(allVitals);
	const [lgShowVitals, setLgShowVitals] = useState(false);
	const [vitals, setVitals] = useState([]);

	useEffect(() => {
		if (!allVitals?.loading) {
			let vitalsarr = [];
			allVitals?.allVitals?.data?.data?.vitals?.forEach((vital) => {
				vitalsarr.push({
					vitalName: vital,
					vitalValue: '',
				});
			});
			setVitals(vitalsarr); // Set vitals with the new array
		}
	}, [allVitals?.loading]);

	console.log(vitals);

	const vitalChange = (value, index) => {
		const newVitals = [...vitals];
		newVitals[index].vitalValue = value;
		setVitals(newVitals);
	};

	console.log(vitals);

	const { allCategoryVitals, error, success } = useSelector(
		(state) => state.getCategoriesVitals
	);

	console.log(location?.state);

	useEffect(() => {
		dispatch(
			getCategoriesVitals(
				location.state.id,
				location?.state?.name,
				location?.state?.userIdx,
				clinicName
			)
		);
	}, [
		history,
		dispatch,
		error,
		success,
		createCategorySuccess,
		createCategoryFailure,
		createCategoryloading,
	]);

	function addVitalOnSubmit(event) {
		event.preventDefault();
		const vitalName = document.getElementById('vitalName').value;
		const vitalUnits = document.getElementById('vitalUnits').value;
		console.log('Vital Name:', vitalName);
		console.log('Vital Units:', vitalUnits);
		setLgShowVitals(false);
		const newVitals = [...vitals];
		newVitals?.push({
			vitalName: vitalName + ' (' + vitalUnits + ')',
			vitalValue: '',
		});
		setVitals(newVitals);

		dispatch(addVitalInDoctor(vitalName + ' (' + vitalUnits + ')', clinicName));
	}

	const [Category, setCategory] = useState('');
	const [lgShow, setLgShow] = useState(false);
	const [chooseCategory, setChooseCategory] = useState([]);
	const [title, setTitle] = useState('');
	const { userdata } = useSelector((state) => state.getUserDataById);
	console.log(userdata);
	useEffect(() => {
		dispatch(
			getUserDataById(
				location.state.id,
				location.state.name,
				location?.state?.userIdx,
				clinicName
			)
		);
	}, []);

	console.log(chooseCategory);
	const addCategoryOnSubmit = (e) => {
		e.preventDefault();
		let flag = false;
		console.log(Category);
		for (let i = 0; i < allCategoryVitals?.data?.data?.category?.length; i++) {
			if (
				allCategoryVitals?.data?.data?.category[i].toLowerCase() ===
				Category?.toLowerCase()
			) {
				flag = true;
			}
		}
		if (flag) {
			alert(Category + ' already exists');
		} else {
			var letters = /^[A-Za-z\s]+$/;
			if (Category === '') {
				alert('Category cannot be empty');
			} else {
				console.log(Category, userdata?.data?.data?.name);
				dispatch(
					createCategory(
						location.state.id,
						Category,
						location?.state?.name,
						location?.state?.userIdx,
						clinicName
					)
				); // add category dispatch function
				let cates = [...chooseCategory];
				cates?.push({
					label: Category,
					value: Category,
				});
				setChooseCategory(cates);
				setLgShow(false);
			}
		}
	};
	const [activeBoxes, setActiveBoxes] = useState([]);
	const [editTemplateclick, seteditTemplateclick] = useState(-1);
	const { templatenames } = useSelector((state) => state.getTemplateNames);
	console.log(templatenames);
	const [chooseEditTemplate, setChooseEditTemplate] =
		useState('Choose Template');
	const [chooseEditTemplateId, setChooseEditTemplateId] = useState('');
	const handleEditTemplateSelect = (eventKey) => {
		seteditTemplateclick(editTemplateclick * -1);
		setChooseEditTemplate(eventKey.split('@')[0]);
		setChooseEditTemplateId(eventKey.split('@')[1]);
		let arr = [];
		setRows(arr);
		setValues(arr);
		dispatch(
			getTemplate1(doctorInfo?.user?._id, eventKey.split('@')[1], clinicName)
		);
	};

	const templates = useSelector((state) => state.getTemplate);

	console.log(templates?.getTemplate?.data?.data?.medicines);
	let editrow = [];
	let editvalue = [];
	const [editRows, setEditRows] = useState([]);
	const [editValues, setEditValues] = useState([]);

	useEffect(() => {
		if (chooseEditTemplate !== 'Choose Template') {
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				editrow.push(i);
			}
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				editvalue.push({
					name: templates?.getTemplate?.data?.data?.medicines[i]?.name,
					dosage: templates?.getTemplate?.data?.data?.medicines[i]?.dosage,
					duration: templates?.getTemplate?.data?.data?.medicines[i]?.duration,
				});
			}
			setEditRows(editrow);
			setEditValues(editvalue);
		}
	}, [templates?.getTemplate?.data?.data?.medicines]);
	console.log(editValues);

	const handleEditChange = (value, index, key) => {
		const editData = [...editValues];
		if (key === 'name') editData[index].name = value;
		else if (key === 'dosagebox') {
			if (value) editData[index].dosage = '';
			else editData[index].dosage = 'O@@';
		} else if (key === 'dosage') {
			if (value === 'M') {
				if (editData[index].dosage?.split(',')?.includes('M')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('M');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(1, 0, 'M');
					editData[index].dosage = valls?.join(',');
				}
			} else if (value === 'A') {
				if (editData[index].dosage?.split(',')?.includes('A')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('A');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(2, 0, 'A');
					editData[index].dosage = valls?.join(',');
				}
			} else if (value === 'N') {
				if (editData[index].dosage?.split(',')?.includes('N')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('N');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(3, 0, 'N');
					editData[index].dosage = valls?.join(',');
				}
			} else {
				editData[index].dosage = value;
			}
		} else if (key === 'duration') editData[index].duration = value;
		console.log(editData);
		setEditValues(editData);
	};

	const handleEditDelete = (index) => {
		const editData = [...editValues];
		if (index > -1) {
			editData.splice(index, 1);
		}
		setEditValues(editData);
		console.log(editValues);
	};

	console.log(editValues);

	const [rows, setRows] = useState([]);
	const [values, setValues] = useState({});
	console.log(rows);
	console.log(values);
	const handleChange = (name, value) => {
		// const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};

	useEffect(() => {
		handleAddRow();

		dispatch(getTemplateNames(doctorInfo?.user?._id, clinicName));
	}, []);

	const [editClicked, setEditClicked] = useState([]);

	const editBtnClicked = () => {
		let editbuttons = [...editClicked];
		editbuttons[activePage - 1] = true;
		setEditClicked(editbuttons);
		setFormElements(prescritpionTemplate[activePage - 1]);
	};

	const handleEditDisable = () => {
		let editbuttons = [...editClicked];
		editbuttons[activePage - 1] = false;
		setEditClicked(editbuttons);
	};

	console.log(editClicked);

	// Form Builder Code

	const [formElements, setFormElements] = useState([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editLabel, setEditLabel] = useState('');
	const [editOptions, setEditOptions] = useState([]);
	const [isRequired, setIsRequired] = useState(false);
	const [editHeaderValue, setEditHeaderValue] = useState('');
	const [editParagraphValue, setEditParagraphValue] = useState('');
	const [draggingElement, setDraggingElement] = useState('');
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [editModalOptions, setEditModalOptions] = useState([]);
	const [newOption, setNewOption] = useState('');
	const [showAddOptionInput, setShowAddOptionInput] = useState(false);

	console.log(selectedOptions);

	const addFormElement = (elementType, label = 'Label Name') => {
		let defaultOptions = [];
		if (
			elementType === 'radio' ||
			elementType === 'checkbox' ||
			elementType === 'select' ||
			elementType === 'multiselect'
		) {
			defaultOptions = ['Option 1', 'Option 2', 'Option 3'];
		}
		setFormElements([
			...formElements,
			{
				type: elementType,
				label:
					elementType === 'header'
						? 'Header'
						: elementType === 'paragraph'
						? 'Paragraph'
						: label,
				value:
					elementType === 'header'
						? 'Header'
						: elementType === 'paragraph'
						? 'Paragraph'
						: '',
				options: elementType === 'header' ? [] : defaultOptions,
				required: false,
			},
		]);
	};

	const handleDragStart = (event, elementType) => {
		event.dataTransfer.setData('elementType', elementType);
		setDraggingElement(elementType);
	};

	const handleValueChange = (event, index) => {
		const updatedElements = [...formElements];
		updatedElements[index].value = event.target.value;
		setFormElements(updatedElements);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const elementType = event.dataTransfer.getData('elementType');
		if (elementType === 'header') {
			addFormElement('header');
		} else if (elementType === 'paragraph') {
			addFormElement('paragraph');
			setDraggingElement(false);
		} else {
			addFormElement(elementType);
		}
		setDraggingElement('');
	};

	const handleItemClick = (elementType) => {
		addFormElement(elementType);
	};

	const handleLabelChange = (event, index) => {
		const updatedElements = [...formElements];
		if (updatedElements[index]) {
			updatedElements[index].label = event.target.value;
			setFormElements(updatedElements);
		}
	};

	const handleOptionChange = (event, index) => {
		const updatedOptions = [...editOptions];
		updatedOptions[index] = event.target.value;
		setEditOptions(updatedOptions);
	};

	const handleAddOption = () => {
		setShowAddOptionInput(true);
	};

	const handleDeleteOption = (index) => {
		const updatedOptions = [...editOptions];
		updatedOptions.splice(index, 1);
		setEditOptions(updatedOptions);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...formElements];
		updatedElements.splice(index, 1);
		setFormElements(updatedElements);
		setSelectedElementIndex(null);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
	};

	const handleEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].label = editLabel;
				if (
					updatedElements[selectedElementIndex].type !== 'header' &&
					updatedElements[selectedElementIndex].type !== 'paragraph'
				) {
					updatedElements[selectedElementIndex].options = editOptions;
					updatedElements[selectedElementIndex].required = isRequired;
				} else if (updatedElements[selectedElementIndex].type === 'header') {
					updatedElements[selectedElementIndex].value = editHeaderValue;
				} else if (updatedElements[selectedElementIndex].type === 'paragraph') {
					updatedElements[selectedElementIndex].value = editParagraphValue;
				}
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const openEditModal = (index) => {
		setSelectedElementIndex(index);
		if (formElements[index]) {
			setEditLabel(formElements[index].label);
			setEditOptions(formElements[index].options);
			setIsRequired(formElements[index].required);
			setEditHeaderValue(formElements[index].value);
			setEditParagraphValue(formElements[index].value);
		}
		setShowEditModal(true);
	};

	const handleSubmitFormBuilder = () => {
		console.log({ formElements });
		let prestemp = [...prescritpionTemplate];
		prestemp[activePage - 1] = formElements;
		setPrescritpionTemplate(prestemp);
		let editbuttons = [...editClicked];
		editbuttons[activePage - 1] = false;
		setEditClicked(editbuttons);
	};

	const addHeaderToForm = () => {
		addFormElement('header');
	};

	const addParagraphToForm = () => {
		addFormElement('paragraph');
	};

	const handleHeaderEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editHeaderValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const handleParagraphEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editParagraphValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const createHistory = () => {
		dispatch(startPrescriptionToStartHistoryReset());
		dispatch(startPrescriptionToStartHistoryRequest());
		dispatch(startPrescriptionToStartHistorySuccess(location?.state));
		window.open(`/clinic/bhuvanshomeopathy/history`, '_blank');
	};

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		dispatch(createPrescriptionReset());
		history(`/clinic/bhuvanshomeopathy/userappointment`, {
			state: {
				id: location.state.id,
				name: location.state.name,
				date: location.state.date,
				userIdx: location.state.userIdx,
				prescriptionstatus: 'already created',
			},
		});
	};

	const handleNextSteps = () => {
		var user_bookingIdArray = [];
		var user_bookingDoctorsArray = [];
		user_bookingIdArray = userdata?.data?.data?.bookingIdArray;
		user_bookingDoctorsArray = userdata?.data?.data?.bookingDoctorsArray;

		history(`/clinic/bhuvanshomeopathy/doctor/nextappointment`, {
			state: {
				id: location.state.id,
				name: location.state.name,
				mno: userdata?.data?.data?.mobilenumber,
				userIdx: location.state.userIdx,
				user_bookingIdArray: user_bookingIdArray,
				user_bookingDoctorsArray: user_bookingDoctorsArray,
			},
		});
		dispatch(createPrescriptionReset());
	};

	const [deletePageBool, setDeletePageBool] = useState(false);

	const deletePage = (index) => {
		if (index === 0) {
			alert('Atleast 1 page needs to be submitted');
		} else {
			// eslint-disable-next-line no-restricted-globals
			if (confirm('Do you really want to delete this page?')) {
				let pages = [...totalPages];
				let removedItem = pages?.pop();
				setTotalPages(pages);

				let templatesSelect = [...templateName];
				templatesSelect?.splice(index, 1);
				setTemplateName(templatesSelect);

				let templatesIdxSelect = [...templateIdx];
				templatesIdxSelect?.splice(index, 1);
				setTemplateIdx(templatesIdxSelect);

				let prescriptionTemplatesSelect = [...prescritpionTemplate];
				prescriptionTemplatesSelect?.splice(index, 1);
				setPrescritpionTemplate(prescriptionTemplatesSelect);

				let editbuttons = [...editClicked];
				editbuttons?.splice(index, 1);
				setEditClicked(editbuttons);

				if (index === totalPages?.length - 1) setDeletePageBool(true);
			}
		}
	};

	useEffect(() => {
		if (deletePageBool) {
			console.log('OK');
			console.log(totalPages);
			setActivePage(totalPages?.length);
			setDeletePageBool(false);
		}
	}, [deletePage]);
	return (
		<div className='page-content'>
			<Meta />
			<Container className='header-center card mt-3'>
				<Row className='justify-content-center mb-3 mt-4'>
					<Col md='3' className='text-center'>
						<h6>Patient: {userdata?.data?.data?.name}</h6>
					</Col>
					<Col md='3' className='text-center'>
						<h6>Age: {userdata?.data?.data?.age}</h6>
					</Col>
					<Col md='3' className='text-center'>
						<h6>Sex: {userdata?.data?.data?.gender}</h6>
					</Col>
					<Col md='3' className='text-center'>
						<h6>Contact: {userdata?.data?.data?.mobilenumber}</h6>
					</Col>
				</Row>
			</Container>
			<Container className='header-center mb-3 mt-3'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Select the filters{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={templateName[activePage - 1]}
							onSelect={TemplateHandler}>
							<div className='dropScroll'>
								{getPrescriptionTemplateNames?.data?.data?.prescriptionTemplatesNamesArray.map(
									(template, index) => (
										<Dropdown.Item
											eventKey={
												template.templateName +
												'@@' +
												template._id +
												'@@' +
												index
											}>
											{template.templateName}
										</Dropdown.Item>
									)
								)}
							</div>
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<Button variant='primary' onClick={createHistory}>
							View/Create History
						</Button>
					</Col>
				</Row>
			</Container>

			<Container>
				<Form className='mt-2'>
					{activePage === 1 && templateName[0] !== 'Choose Template' && (
						<>
							<Container className='header-center mt-4 mb-4 card'>
								<Row className='mt-2 mb-2 justify-content-center'>
									<Col md='auto'>
										<h3>Vitals</h3>
									</Col>
								</Row>
							</Container>
							<Container className='header-center mt-4 mb-4 card'>
								<Row className='mt-4 mb-2'>
									{vitals?.map((vital, index) => (
										<Col md='3'>
											<Col md='12'>{vital?.vitalName}</Col>
											<Col md='12'>
												<input
													type='text'
													className='form-control '
													value={vital?.vitalValue}
													onChange={(e) => vitalChange(e.target.value, index)}
												/>
											</Col>
										</Col>
									))}
								</Row>
								<Row className='mt-2 mb-4 justify-content-center'>
									<Button
										variant='secondary'
										onClick={() => setLgShowVitals(true)}>
										Add Vitals
									</Button>
								</Row>
							</Container>
							<Modal
								size='lg'
								aria-labelledby='example-modal-sizes-title-lg'
								show={lgShowVitals}
								onHide={() => setLgShowVitals(false)}>
								<Modal.Header closeButton>
									<Modal.Title id='example-modal-sizes-title-lg'>
										Add Vital
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<form className='form-group' onSubmit={addVitalOnSubmit}>
										<Container className='header-center mt-4 mb-5'>
											<Row className='justify-content-center'>
												<Col md='auto' className='text-center mt-1'>
													<label htmlFor='vitalName'>
														Vital Name <span className='text-danger'>*</span>
													</label>
												</Col>
												<Col md='auto' className='text-center mt-1'>
													<input
														className='form-control'
														id='vitalName'
														type='text' // Change input type
														required='required'
														name='vitalName'
													/>
												</Col>

												<Col md='auto' className='text-center mt-1'>
													<label htmlFor='vitalUnits'>
														Vital units <span className='text-danger'>*</span>
													</label>
												</Col>
												<Col md='auto' className='text-center mt-1'>
													<input
														className='form-control'
														id='vitalUnits'
														type='text' // Change input type
														required='required'
														name='vitalUnits'
													/>
												</Col>
											</Row>
											<Row className='justify-content-center mt-3'>
												<Col md='auto' className='text-center mt-1'>
													<Button className='btn' type='submit'>
														Add
													</Button>
												</Col>
											</Row>
										</Container>
									</form>
								</Modal.Body>
							</Modal>
							<Container className='header-center mt-4 mb-4 card'>
								<Row className='mt-4 mb-2'>
									<Col md='auto' className='text-center mt-1'>
										<div>Category:</div>
									</Col>
									<Col md='3' className='text-center mt-1'>
										<MultiSelect
											options={allCategoryVitals?.data?.data?.category?.map(
												(option) => ({
													label: option,
													value: option,
												})
											)}
											value={chooseCategory}
											onChange={setChooseCategory}
											labelledBy='Select'
										/>
									</Col>
									<Col md='auto' className='text-center mt-1'>
										<Button variant='secondary' onClick={() => setLgShow(true)}>
											Add Category
										</Button>
									</Col>
								</Row>

								<Row className='mt-2 mb-4'>
									<Col md='auto' className='text-center mt-1'>
										<div>Title:</div>
									</Col>
									<Col md='auto' className='text-center mt-1'>
										<textarea
											rows={1}
											cols={100}
											className='form-control'
											onChange={(e) => setTitle(e.target.value)}
											value={title}></textarea>
									</Col>
								</Row>
							</Container>
							<Modal
								size='lg'
								aria-labelledby='example-modal-sizes-title-lg'
								show={lgShow}
								onHide={() => setLgShow(false)}>
								<Modal.Header closeButton>
									<Modal.Title id='example-modal-sizes-title-lg'>
										Add Category
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<form className='form-group' onSubmit={addCategoryOnSubmit}>
										<Container className='header-center mt-4 mb-5'>
											<Row className='justify-content-center'>
												<Col md='auto' className='text-center mt-1'>
													<label htmlFor='Category'>
														Category <span className='text-danger'>*</span>
													</label>
												</Col>
												<Col md='auto' className='text-center mt-1'>
													<input
														className='form-control'
														id='Category'
														type='Category'
														required='required'
														value={Category}
														onChange={(e) => setCategory(e.target.value)}
													/>
												</Col>
												<Col md='auto' className='text-center mt-1'>
													<Button className='btn' type='submit'>
														Add
													</Button>
												</Col>
											</Row>
										</Container>
									</form>
								</Modal.Body>
							</Modal>
						</>
					)}
					{templateName[activePage - 1] !== 'Choose Template' &&
						templateName[activePage - 1] !== 'Medication' && (
							<Container>
								<Row className='justify-content-end'>
									<Col xs='auto'>
										<Button
											variant='outline-success'
											className='mb-4'
											onClick={editBtnClicked}>
											Edit Form
										</Button>
									</Col>
								</Row>
							</Container>
						)}

					{templateName[activePage - 1] !== 'Choose Template' &&
						templateName[activePage - 1] !== 'Medication' &&
						!editClicked[activePage - 1] &&
						Array?.isArray(prescritpionTemplate[activePage - 1]) &&
						prescritpionTemplate[activePage - 1]?.map((element, index) => {
							return (
								<div key={index} className='form-element'>
									{element.type === 'header' || element.type === 'paragraph' ? (
										element?.type === 'header' ? (
											<h3 className='header-center'>{element.value}</h3>
										) : (
											<p>{element.value}</p>
										)
									) : (
										<>
											<Form.Label>{element.label}</Form.Label>
											{element.type === 'select' ? (
												<Form.Control
													as='select'
													onChange={(e) =>
														handleSelectChange(
															e,
															element.label,
															activePage - 1,
															index
														)
													}>
													{element.options.map((option, optionIndex) => (
														<option key={optionIndex}>{option}</option>
													))}
												</Form.Control>
											) : element.type === 'multiselect' && element.options ? (
												<MultiSelect
													options={element.options.map((option) => ({
														label: option,
														value: option,
													}))}
													onChange={(selectedOptions) =>
														handleMultiselectChange(
															selectedOptions,
															element.label,
															activePage - 1,
															index
														)
													}
													value={element.value || []} // Ensure that formValues[element.label] is an array of objects with label and value properties
													labelledBy='Select'
												/>
											) : element.type === 'checkbox' ? (
												<div>
													{element.options.map((option, optionIndex) => (
														<Form.Check
															key={optionIndex}
															type='checkbox'
															label={option}
															required={element.required}
															onChange={(e) =>
																handleCheckboxRadioChange(
																	e,
																	element.label,
																	option,
																	activePage - 1,
																	index
																)
															}
															checked={prescritpionTemplate[activePage - 1][
																index
															]?.value?.includes(option)}
														/>
													))}
												</div>
											) : // Inside the return statement of your component

											element.type === 'radio' ? (
												<div>
													{element.options.map((option, optionIndex) => (
														<Form.Check
															key={optionIndex}
															type='radio'
															label={option}
															required={element.required}
															onChange={(e) =>
																handleCheckboxRadioChange(
																	e,
																	element.label,
																	option,
																	activePage - 1,
																	index
																)
															}
															checked={element?.value === option} // Check if the option is selected
														/>
													))}
												</div>
											) : element.type === 'file' ? (
												<input
													type='file'
													onChange={(e) =>
														handleInputChange(
															e,
															element.label,
															activePage - 1,
															index
														)
													}
													className='form-control'
												/>
											) : element.type !== 'header' &&
											  element.type !== 'paragraph' ? (
												element.type === 'input' ? (
													<FormControl
														type={element.type}
														placeholder='Enter Value'
														value={element?.value || ''}
														onChange={(e) =>
															handleInputChange(
																e,
																element.label,
																activePage - 1,
																index
															)
														}
													/>
												) : (
													<textarea
														className='form-control'
														placeholder='Enter Value'
														value={element?.value || ''}
														onChange={(e) =>
															handleInputChange(
																e,
																element.label,
																activePage - 1,
																index
															)
														}></textarea>
												)
											) : null}
										</>
									)}
								</div>
							);
						})}
					{templateName[activePage - 1] !== 'Choose Template' &&
						templateName[activePage - 1] !== 'Medication' &&
						editClicked[activePage - 1] &&
						Array?.isArray(prescritpionTemplate[activePage - 1]) && (
							<div className='form-builder'>
								<Row>
									<Col md={3} className='toolbox'>
										<h3>Toolbox</h3>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'input')}>
											Input
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'textarea')}>
											Textarea
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'select')}>
											Select
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'checkbox')}>
											Checkbox
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'radio')}>
											Radio
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'header')}>
											Header
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'paragraph')}>
											Paragraph
										</div>
										<div
											className='toolbox-item'
											draggable
											onDragStart={(e) => handleDragStart(e, 'multiselect')}>
											MultiSelect
										</div>
									</Col>
									<Col md={9}>
										<div
											className={`form-canvas ${
												draggingElement === 'paragraph'
													? 'dragged-paragraph'
													: ''
											}`}
											onDragOver={(e) => e.preventDefault()}
											onDrop={handleDrop}>
											<Form>
												{formElements.map((element, index) => (
													<div
														key={index}
														className={`form-element ${
															selectedElementIndex === index ? 'selected' : ''
														}`}>
														<div className='form-element-header'>
															<div className='label-edit-delete'>
																{element.type !== 'header' &&
																element.type !== 'paragraph' ? (
																	<FormLabel className='label'>
																		{element.label}
																	</FormLabel>
																) : element.type !== 'paragraph' ? (
																	<h3>{element.value}</h3>
																) : (
																	<p className='paragraph'>{element.value}</p>
																)}
																<div className='edit-delete-buttons'>
																	<button
																		type='button'
																		className='edit-icon btn'
																		onClick={() => openEditModal(index)}>
																		<FontAwesomeIcon icon={faEdit} />
																	</button>
																	<button
																		type='button'
																		className='delete-icon btn'
																		onClick={() => handleDeleteElement(index)}>
																		<FontAwesomeIcon icon={faTrash} />
																	</button>
																</div>
															</div>
														</div>
														<div className='form-element-input'>
															{element.type === 'select' ? (
																<Form.Control as='select'>
																	{element.options.map(
																		(option, optionIndex) => (
																			<option key={optionIndex}>
																				{option}
																			</option>
																		)
																	)}
																</Form.Control>
															) : element.type === 'multiselect' ? (
																<MultiSelect
																	options={element.options.map((option) => ({
																		label: option,
																		value: option,
																	}))}
																	value={selectedOptions}
																	onChange={setSelectedOptions}
																	labelledBy='Select'
																/>
															) : element.type === 'checkbox' ? (
																<div>
																	{element.options.map(
																		(option, optionIndex) => (
																			<Form.Check
																				key={optionIndex}
																				type='checkbox'
																				label={option}
																				required={element.required}
																			/>
																		)
																	)}
																</div>
															) : element.type === 'radio' ? (
																<div>
																	{element.options.map(
																		(option, optionIndex) => (
																			<Form.Check
																				key={optionIndex}
																				type='radio'
																				label={option}
																				required={element.required}
																			/>
																		)
																	)}
																</div>
															) : element.type !== 'header' &&
															  element.type !== 'paragraph' ? (
																element.type === 'input' ? (
																	<FormControl
																		type={element.type}
																		placeholder='Enter Value'
																		value={element.value}
																		onChange={(e) =>
																			handleValueChange(e, index)
																		}
																	/>
																) : (
																	<textarea
																		rows={2}
																		cols={100}
																		className='form-control'
																		onChange={(e) =>
																			handleValueChange(e, index)
																		}
																		value={element.value}></textarea>
																)
															) : null}
														</div>
													</div>
												))}
											</Form>
										</div>
									</Col>
								</Row>

								<Modal show={showEditModal} onHide={handleEditModalClose}>
									<Modal.Header closeButton>
										<Modal.Title>Edit Form Element</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										{formElements[selectedElementIndex]?.type === 'header' ? (
											<>
												<FormLabel>Edit Value:</FormLabel>
												<FormControl
													type='text'
													value={editHeaderValue}
													onChange={(e) => setEditHeaderValue(e.target.value)}
												/>
											</>
										) : formElements[selectedElementIndex]?.type ===
										  'paragraph' ? (
											<>
												<FormLabel>Edit Value:</FormLabel>
												<FormControl
													type='text'
													value={editParagraphValue}
													onChange={(e) =>
														setEditParagraphValue(e.target.value)
													}
												/>
											</>
										) : (
											<>
												<FormLabel>Edit Label:</FormLabel>
												<FormControl
													type='text'
													value={editLabel}
													onChange={(e) => setEditLabel(e.target.value)}
												/>
												{formElements[selectedElementIndex]?.type !==
													'header' &&
												formElements[selectedElementIndex]?.type !==
													'paragraph' &&
												(formElements[selectedElementIndex]?.type ===
													'checkbox' ||
													formElements[selectedElementIndex]?.type ===
														'radio' ||
													formElements[selectedElementIndex]?.type ===
														'select' ||
													formElements[selectedElementIndex]?.type ===
														'multiselect') ? (
													<>
														<FormLabel>Edit Options:</FormLabel>
														{editOptions.map((option, index) => (
															<div key={index} className='header-center'>
																<Row className='mt-1'>
																	<Col md='10'>
																		<FormControl
																			type='text'
																			value={option}
																			onChange={(e) =>
																				handleOptionChange(e, index)
																			}
																		/>
																	</Col>
																	<Col md='1'>
																		<Button
																			variant='danger'
																			onClick={() => handleDeleteOption(index)}>
																			<FontAwesomeIcon icon={faTrash} />
																		</Button>
																	</Col>
																</Row>
																{index === editOptions.length - 1 && (
																	<Button
																		variant='primary'
																		onClick={handleAddOption}
																		className='mt-2 mb-2'>
																		<FontAwesomeIcon icon={faPlus} /> Add
																	</Button>
																)}
															</div>
														))}
														{showAddOptionInput && (
															<Row>
																<Col md='10'>
																	<FormControl
																		type='text'
																		value={newOption}
																		onChange={(e) =>
																			setNewOption(e.target.value)
																		}
																		placeholder='New Option'
																	/>
																</Col>
																<Col md='1'>
																	<Button
																		variant='primary'
																		onClick={() => {
																			setEditOptions([
																				...editOptions,
																				newOption,
																			]);
																			setNewOption('');
																			setShowAddOptionInput(false);
																		}}>
																		<FontAwesomeIcon icon={faCheck} />
																	</Button>
																</Col>
															</Row>
														)}
													</>
												) : null}
												{formElements[selectedElementIndex]?.type !==
													'header' &&
													formElements[selectedElementIndex]?.type !==
														'paragraph' && (
														<FormCheck
															type='checkbox'
															label='Required'
															checked={isRequired}
															onChange={() => setIsRequired(!isRequired)}
														/>
													)}
											</>
										)}
									</Modal.Body>
									<Modal.Footer>
										<Button variant='secondary' onClick={handleEditModalClose}>
											Close
										</Button>
										{formElements[selectedElementIndex]?.type === 'header' ? (
											<Button
												variant='primary'
												onClick={handleHeaderEditModalSave}>
												Save Changes
											</Button>
										) : formElements[selectedElementIndex]?.type ===
										  'paragraph' ? (
											<Button
												variant='primary'
												onClick={handleParagraphEditModalSave}>
												Save Changes
											</Button>
										) : (
											<Button variant='primary' onClick={handleEditModalSave}>
												Save Changes
											</Button>
										)}
									</Modal.Footer>
								</Modal>
							</div>
						)}
				</Form>

				{templateName[activePage - 1] === 'Medication' && (
					<Container className='card mt-4'>
						<div className='row mt-4 mb-2 section'>
							<div className='col-md-4'>Medication</div>
						</div>
						<div className='row mt-4 mb-2 section'>
							<div className='col-md-2'>Choose Template:</div>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={chooseEditTemplate}
								onSelect={handleEditTemplateSelect}>
								<div className='dropButton'>
									{templatenames?.data?.data ? (
										templatenames?.data?.data.map((name, index) => (
											<Dropdown.Item eventKey={name + '@' + index}>
												{name}
											</Dropdown.Item>
										))
									) : (
										<Dropdown.Item disabled>No items found</Dropdown.Item>
									)}
								</div>
							</DropdownButton>
						</div>
						<Row className='mt-2'>
							{editValues.map((medicine, index) => {
								let durationFromMedication =
									medicine?.duration?.split(' ')[1] === 'Days'
										? 1
										: medicine?.duration?.split(' ')[1] === 'Weeks'
										? 7
										: medicine?.duration?.split(' ')[1] === 'Months'
										? 30
										: 365;
								let frequencyFromMedication = medicine?.dosage
									?.split(',')
									?.includes('M')
									? 1
									: 0;

								frequencyFromMedication =
									frequencyFromMedication +
									(medicine?.dosage?.split(',')?.includes('A') ? 1 : 0);
								frequencyFromMedication =
									frequencyFromMedication +
									(medicine?.dosage?.split(',')?.includes('N') ? 1 : 0);

								let quantity =
									frequencyFromMedication *
									durationFromMedication *
									Number(medicine?.duration?.split(' ')[0]);
								return (
									<Card
										style={{
											width: '22rem',
											marginLeft: '1%',
											marginTop: '1%',
										}}>
										<Card.Body>
											<Card.Text>
												<Row className='justify-content-end'>
													<button
														className='btn btn-outline-danger'
														onClick={() => handleEditDelete(index)}>
														<i className='fa fa-trash'></i>
													</button>
												</Row>
												<Row className='mt-2'>
													<Col>
														<input
															className='form-control'
															// name={`name-${index}`}
															value={medicine?.name}
															onChange={(e) =>
																handleEditChange(e.target.value, index, 'name')
															}
															placeholder='Enter Medicine'
														/>
													</Col>
												</Row>
												<Row className='mt-2'>
													<Col>
														<button
															className='btn btn-primary'
															onClick={(e) =>
																handleEditChange(
																	activeBoxes[index],
																	index,
																	'dosagebox'
																)
															}>
															<i class='fas fa-sync-alt'></i>
														</button>
														{medicine?.dosage?.includes('O@@') ? (
															<input
																type='text'
																// name={`dosage-${index}`}
																value={medicine?.dosage?.split('@@')[1]}
																onChange={(e) =>
																	handleEditChange(
																		'O@@' + e.target.value,
																		index,
																		'dosage'
																	)
																}
															/>
														) : (
															<>
																<ButtonGroup aria-label='Basic example'>
																	<Button
																		onClick={() =>
																			handleEditChange(
																				'B/F,' +
																					medicine?.dosage
																						?.split(',')
																						?.slice(1)
																						?.join(','),
																				index,
																				'dosage'
																			)
																		}
																		variant={
																			medicine?.dosage?.includes('B/F')
																				? 'success'
																				: 'secondary'
																		}>
																		B/F
																	</Button>
																	<Button
																		onClick={() =>
																			handleEditChange(
																				'A/F,' +
																					medicine?.dosage
																						?.split(',')
																						?.slice(1)
																						?.join(','),
																				index,
																				'dosage'
																			)
																		}
																		variant={
																			medicine?.dosage?.includes('A/F')
																				? 'success'
																				: 'secondary'
																		}>
																		A/F
																	</Button>
																</ButtonGroup>
																<Button
																	onClick={() =>
																		handleEditChange('M', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('M')
																			? 'success'
																			: 'outline-success'
																	}>
																	M
																</Button>
																<Button
																	onClick={() =>
																		handleEditChange('A', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('A')
																			? 'success'
																			: 'outline-success'
																	}>
																	A
																</Button>
																<Button
																	onClick={() =>
																		handleEditChange('N', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('N')
																			? 'success'
																			: 'outline-success'
																	}>
																	N
																</Button>
																<DropdownButton
																	className='mt-2'
																	key='down-centered'
																	id={`dropdown-button-drop-down-centered`}
																	drop='down-centered'
																	variant='success'
																	title={medicine?.duration?.split(' ')[2]}>
																	<Dropdown.Item
																		eventKey={"Doesn't Repeat"}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					" Doesn't Repeat",
																				index,
																				'duration'
																			)
																		}>
																		{"Doesn't Repeat"}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Daily'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Daily',
																				index,
																				'duration'
																			)
																		}>
																		{'Daily'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Weekly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Weekly',
																				index,
																				'duration'
																			)
																		}>
																		{'Weekly'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Monthly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Monthly',
																				index,
																				'duration'
																			)
																		}>
																		{'Monthly'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Yearly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Yearly',
																				index,
																				'duration'
																			)
																		}>
																		{'Yearly'}
																	</Dropdown.Item>
																</DropdownButton>
															</>
														)}
													</Col>
												</Row>
												<Row className='mt-2'>
													<Col md='2'>
														<button
															className='btn btn-primary '
															onClick={() =>
																handleEditChange(
																	(
																		Number(medicine?.duration?.split(' ')[0]) -
																		1
																	).toString() +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}>
															<i className='fas fa-minus'></i>
														</button>
													</Col>
													<Col md='4'>
														<input
															className='form-control'
															type='text'
															// name={`duration-${index}`}
															value={medicine?.duration?.split(' ')[0]}
															onChange={(e) =>
																handleEditChange(
																	e.target.value +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}
														/>
													</Col>
													<Col md='2'>
														<button
															className='btn btn-primary '
															onClick={() =>
																handleEditChange(
																	(
																		Number(medicine?.duration?.split(' ')[0]) +
																		1
																	).toString() +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}>
															<i className='fas fa-plus'></i>
														</button>
													</Col>
													<Col md='4'>
														<DropdownButton
															key='down-centered'
															id={`dropdown-button-drop-down-centered`}
															drop='down-centered'
															variant='success'
															title={medicine?.duration?.split(' ')[1]}>
															<Dropdown.Item
																eventKey={'Days'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Days ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Days'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Weeks'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Weeks ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Weeks'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Months'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Months ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Months'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Years'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Years ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Years'}
															</Dropdown.Item>
														</DropdownButton>
													</Col>
												</Row>
												{/* <Row className='mt-2'>
													<Col>
														<input
															className='form-control'
															type='text'
															value={`Quantity: ${quantity ? quantity : 0}`}
															onChange={(e) => {
																quantity = Number(e?.target?.value);
															}}
														/>
													</Col>
												</Row> */}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}

							{rows.map((rowIndex) => (
								<DynamicRow
									index={rowIndex}
									values={values}
									handleChange={handleChange}
									handleDelete={handleDeleteRow}
								/>
							))}
						</Row>
						<div className='row mt-4 mb-2 section'>
							<button
								className='btn btn-secondary offset-md-5'
								onClick={handleAddRow}>
								+ Add more medicines
							</button>
						</div>
					</Container>
				)}
			</Container>
			{editClicked[activePage - 1] ? (
				<Container className='pagination-container mt-5'>
					<Pagination>
						{totalPages?.map((number) => (
							<Pagination.Item
								key={number + 1}
								active={number + 1 === activePage}
								onClick={() => handlePageChange(number + 1)}
								disabled>
								{number + 1} {templateName[number]}{' '}
							</Pagination.Item>
						))}

						<Button
							variant='secondary'
							onClick={handleAddPage}
							className='ml-1'
							disabled>
							Add Page
						</Button>
						<button
							className='btn btn-danger'
							onClick={() => deletePage(activePage - 1)}
							disabled>
							Delete current page <i className='fa fa-trash'></i>
						</button>
					</Pagination>
				</Container>
			) : (
				<Container className='pagination-container mt-5'>
					<Pagination>
						{totalPages?.map((number) => (
							<>
								<Pagination.Item
									key={number + 1}
									active={number + 1 === activePage}
									onClick={() => handlePageChange(number + 1)}>
									{number + 1} {templateName[number]}{' '}
								</Pagination.Item>
							</>
						))}

						<Button
							variant='secondary'
							onClick={handleAddPage}
							className='ml-1'>
							Add Page
						</Button>

						<button
							className='btn btn-danger'
							onClick={() => deletePage(activePage - 1)}>
							Delete current page <i className='fa fa-trash'></i>
						</button>
					</Pagination>
				</Container>
			)}

			{templateName[activePage - 1] !== 'Choose Template' &&
				(!editClicked[activePage - 1] ? (
					<Row className='justify-content-center'>
						<Button variant='primary' onClick={handleSubmitForm}>
							Submit
						</Button>
					</Row>
				) : (
					<Row className='justify-content-center'>
						<Button variant='primary' onClick={handleSubmitFormBuilder}>
							Save Changes
						</Button>
						<Button
							variant='danger'
							className='ml-3'
							onClick={handleEditDisable}>
							Discard Changes
						</Button>
					</Row>
				))}

			{(createPrescriptionSuccess || onSpotPrescriptionSuccess) && (
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Prescription created succesfully !</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Do you want plan a next visit/ remainder or refer to another doctor.
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Skip
						</Button>
						<Button variant='primary' onClick={handleNextSteps}>
							Continue
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</div>
	);
};

export default StartPrescriptionScreens;
