/** @format */
import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getTimeslots } from '../../redux/userApiCalls';
import { getMyAppointments } from '../../redux/doctorRedux/doctorApiCalls';
import { getuserByIdForPrescription } from '../../redux/prescriptionRedux/prescriptionApiCalls';
import Form from 'react-bootstrap/Form';
import { getMyAppointmentsReset } from '../../redux/doctorRedux/Appointment_Management/getMyAppointments';
import { TimeSlotGetReset } from '../../redux/getTimeSlots';
import { getuserByIdForPrescriptionReset } from '../../redux/prescriptionRedux/getuserByIdForPrescription';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';
import { useParams } from 'react-router-dom';
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from '../../redux/UINavigationRedux/StartPrescriptionToStartHistory';
import { getPatientHistory } from '../../redux/historyRedux/historyApiCalls';
import { getHistoryByIdxReset } from '../../redux/historyRedux/History_Management/getHistoryByIdx';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Table from 'react-bootstrap/Table';

const GetUserPrescriptionsScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	//success and failure
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	console.log(doctorInfo?.user);
	const getUserDataByIdData = useSelector(
		(state) => state.getuserByIdForPrescription
	);
	const userdata = getUserDataByIdData.userdata;
	const success = getUserDataByIdData.success;
	const loading = getUserDataByIdData.loading;
	const error = getUserDataByIdData.error;
	// Accordian Collapse
	const [open, setOpen] = useState('1');
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	const [startPres, setStartPres] = useState(false);

	const today = new Date();
	const year = today.getFullYear();
	const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
	const day = today.getDate();

	const curDate = year * 10000 + month * 100 + day;

	console.log(location.state?.id, location.state?.name);

	console.log(userdata);
	useEffect(() => {
		console.log(location.state.id, location.state.name);
		dispatch(
			getuserByIdForPrescription(
				location.state?.id,
				location.state?.name,
				clinicName
			)
		);
	}, []);

	const prescriptionByDate = [];
	console.log(JSON.stringify(prescriptionByDate) + 'line 78');
	let scheduledObj = '';
	for (let i = 0; i < userdata?.data?.data?.scheduledAppointment?.length; i++) {
		if (
			userdata?.data?.data?.scheduledAppointment[i].doctor ==
			doctorInfo?.user._id
		) {
			scheduledObj = userdata?.data?.data?.scheduledAppointment[i];
			console.log(scheduledObj);
			//   scheduledObj.date = userdata?.data?.data?.scheduledAppointment[i].date
			//     .split("-")
			//     .reverse()
			//     .join("-");
		}
	}

	//Filter dropdown
	const [filterOption, setFilterOption] = useState('Date');
	const handleFilterSelect = (eventKey) => {
		setFilterOption(eventKey);
	};

	const [categoryOption, setCategoryOption] = useState('Choose Category');
	const [categoryDropdown, setcategoryDropdown] = useState('d-none');

	const [ind, setInd] = useState(-1);

	const handleCategoryNameSelect = (eventKey) => {
		setCategoryOption(eventKey);
		for (let i = 0; i < userdata?.data?.data?.prescCategory.length; i++) {
			if (eventKey === userdata?.data?.data?.prescCategory[i]?.category) {
				setInd(i);
			}
		}
	};

	const handleCategorySelect = () => {
		setcategoryDropdown('d-block');
	};

	const handleDateSelect = () => {
		setcategoryDropdown('d-none');
	};

	const startPrescription = () => {
		history(`/clinic/bhuvanshomeopathy/startprescription`, {
			state: {
				id: location.state.id,
				name: location.state.name,
				date: location.state.date,
				hasPaid: location.state.hasPaid,
				userIdx: location?.state?.userIdx,
				bookingId: location?.state?.bookingId,
			},
		});
	};

	const createHistory = () => {
		let data = {
			id: location.state.id,
			name: location.state.name,
			date: location.state.date,
			hasPaid: location.state.hasPaid,
			userIdx: location?.state?.userIdx,
			bookingId: location?.state?.bookingId,
		};
		dispatch(startPrescriptionToStartHistoryReset());
		dispatch(startPrescriptionToStartHistoryRequest());
		dispatch(startPrescriptionToStartHistorySuccess(data));
		window.open(`/clinic/bhuvanshomeopathy/history`, '_blank');
	};

	console.log(location?.state);

	// const viewBtn = (id, age, gender, bgrp, name) => {
	// 	history(`/clinic/bhuvanshomeopathy/doctor/viewpres`, {
	// 		state: {
	// 			id: id,
	// 			age: age,
	// 			gender: gender,
	// 			bgrp: bgrp,
	// 			name: name,
	// 			page: 'Doc2Screen',
	// 		},
	// 	});
	// };

	const viewBtn = (id, age, gender, bgrp, name, activeTests) => {
		const data = {
			id: id,
			age: age,
			gender: gender,
			bgrp: bgrp,
			name: name,
			index: location?.state?.userIdx,
			userId: location?.state?.id,
		};

		const newWindow = window.open(
			`/clinic/bhuvanshomeopathy/doctor/viewpres`,
			'_blank'
		);
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	//ALerts
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log('error');
		dispatch(getuserByIdForPrescriptionReset());
	};

	console.log(location.state?.date, curDate, userdata?.data?.data?.isBooked);
	// prescriptionByDate?.map((pres, index) =>
	// 	console.log(
	// 		`${pres.bookedDate.toString().substr(6, 2)}-${pres.bookedDate
	// 			.toString()
	// 			.substr(4, 2)}-${pres.bookedDate.toString().substr(0, 4)}`
	// 	)
	// );

	const getData = (eventKey) => {
		console.log('111111');
		if (eventKey === 'history') {
			dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
		}
	};

	useEffect(() => {
		debugger;
		if (userdata?.data?.data?.historyId) {
			dispatch(getHistoryByIdxReset());
			dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
		}
	}, [loading]);

	let { getHistoryByIdx } = useSelector((state) => state.getHistoryByIdx);

	return (
		<div className='pageSize'>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup6}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			<h4 className='header-center'>Patient Name: {location.state?.name}</h4>
			<a
				role='button'
				href={`/clinic/bhuvanshomeopathy/doctor/patientstobevisited`}
				className='btn offset-1 back-button'>
				<i className='fa fa-angle-left custom fa-2x'></i>
			</a>
			<Container className='header-center card mt-5'>
				<Row className='justify-content-center mb-4 mt-4'>
					<Col md='4' className='text-center'>
						<h6>Age: {userdata?.data?.data?.age}</h6>
					</Col>
					<Col md='4' className='text-center'>
						<h6>Sex: {userdata?.data?.data?.gender}</h6>
					</Col>
					<Col md='4' className='text-center'>
						<h6>Contact: {userdata?.data?.data?.mobilenumber}</h6>
					</Col>
				</Row>
			</Container>
			{doctorInfo && (
				<Container className='header-center card mt-5'>
					<Row className='justify-content-center mb-4 mt-4'>
						<Col md='3' className='text-center'>
							<h6>Date: {`${day}-${month}-${year}`}</h6>
						</Col>
						<Col md='3' className='text-center'>
							<h6>
								Doctor:{' '}
								{doctorInfo !== undefined
									? doctorInfo?.user?.name
									: location?.state?.docname}
							</h6>
						</Col>
						{Number(
							location.state?.date?.toString()?.split('')[6] +
								location.state?.date?.toString()?.split('')[7]
						) === day &&
							doctorInfo &&
							location?.state?.prescriptionstatus !== 'already created' &&
							userdata?.data?.data?.isBooked && (
								<Col md='3' className='text-center'>
									<button
										className='btn btn-success'
										onClick={() => startPrescription()}>
										Start Prescription
									</button>
								</Col>
							)}
						<Col md='3' className='text-center'>
							<button className='btn btn-success' onClick={createHistory}>
								View/Create History
							</button>
						</Col>
					</Row>

					{scheduledObj?.message !== undefined && (
						<Row className='justify-content-center mb-4 mt-4'>
							<h6 className='text-danger'>
								You have requested the user for a scheduled appointment on
								{' ' +
									`${scheduledObj?.date
										.toString()
										.substr(6, 2)}-${scheduledObj?.date
										.toString()
										.substr(4, 2)}-${scheduledObj?.date
										.toString()
										.substr(0, 4)}`}{' '}
								for the reason :{' ' + scheduledObj?.message}
							</h6>
						</Row>
					)}
				</Container>
			)}

			<Container className='mt-3'>
				<Tabs
					onSelect={getData}
					defaultActiveKey='prescriptioninfo'
					id='uncontrolled-tab-example'
					className='mb-3'>
					<Tab eventKey='prescriptioninfo' title='Prescription Info'>
						<div className='card container mt-2'>
							<Row className='mt-4 mb-4'>
								<Col md='auto' className='text-center mt-1'>
									<h6>
										Choose Filter
										<span className='text-danger'>
											<b>*</b>
										</span>
									</h6>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={filterOption}
										onSelect={handleFilterSelect}>
										<Dropdown.Item eventKey={'Date'} onClick={handleDateSelect}>
											{'Date'}
										</Dropdown.Item>
										<Dropdown.Item
											eventKey={'Category'}
											onClick={handleCategorySelect}>
											{'Category'}
										</Dropdown.Item>
									</DropdownButton>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<DropdownButton
										className={categoryDropdown}
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={categoryOption}
										onSelect={handleCategoryNameSelect}>
										{userdata?.data?.data?.category?.map(
											(categories, index) => (
												<Dropdown.Item eventKey={categories}>
													{categories}
												</Dropdown.Item>
											)
										)}
									</DropdownButton>
								</Col>
							</Row>
							<div className='row mt-5 mb-3'>
								<h6 className='col-3 col-md-2'>Date</h6>
								<h6 className='col-3 col-md-2'>Doctor</h6>
								<h6 className='col-3 col-md-2'>Category</h6>
								<h6 className='col-3 col-md-5'>Issue</h6>
							</div>
							{filterOption === 'Date' &&
								userdata?.data?.data?.prescDate?.map((pres, index) => (
									<div className='row mt-2 mb-2'>
										<h6 className='col-3 col-md-2'>
											{pres?.bookedDate.toString().slice(6, 8) +
												'-' +
												pres?.bookedDate.toString().slice(4, 6) +
												'-' +
												pres?.bookedDate.toString().slice(0, 4)}
										</h6>
										<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
										<h6 className='col-3 col-md-2'>{pres?.category}</h6>
										<h6 className='col-3 col-md-5 d-block d-md-none'>
											{' '}
											{pres?.title.slice(0, 20)}. . .
										</h6>
										<h6 className='col-3 col-md-5 d-none d-md-block'>
											{' '}
											{pres?.title.slice(0, 105)}. . .
										</h6>
										<button
											className='col-12 col-md-4 offset-md-4 btn btn-secondary'
											onClick={() =>
												viewBtn(
													pres?.prescriptionId,
													userdata?.data?.data?.age,
													userdata?.data?.data?.gender,
													userdata?.data?.data?.vitals[0]?.vitalValue,
													location.state?.name
												)
											}>
											View Prescription
										</button>
									</div>
								))}
							{filterOption === 'Category' &&
								userdata?.data?.data?.prescCategory[ind]?.prescriptionIds?.map(
									(pres, index) => (
										<div className='row mt-2 mb-2'>
											<h6 className='col-3 col-md-2'>{`${pres.bookedDate
												.toString()
												.substr(6, 2)}-${pres.bookedDate
												.toString()
												.substr(4, 2)}-${pres.bookedDate
												.toString()
												.substr(0, 4)}`}</h6>
											<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
											<h6 className='col-3 col-md-2'>
												{userdata?.data?.data?.prescCategory[ind]?.category}
											</h6>
											<h6 className='col-3 col-md-5 d-block d-md-none'>
												{' '}
												{pres?.title.slice(0, 20)}. . .
											</h6>
											<h6 className='col-3 col-md-5 d-none d-md-block'>
												{' '}
												{pres?.title.slice(0, 105)}. . .
											</h6>
											<button
												className='col-12 col-md-4 offset-md-4 btn btn-secondary'
												onClick={() =>
													viewBtn(
														pres?.prescriptionId,
														userdata?.data?.data?.age,
														userdata?.data?.data?.gender,
														userdata?.data?.data?.vitals[0]?.vitalValue,
														location.state?.name
													)
												}>
												View Prescription
											</button>
										</div>
									)
								)}
						</div>
					</Tab>
					<Tab eventKey='history' title='History'>
						{getHistoryByIdx?.data?.data?.history?.map((history, i) => {
							let timestamp = Number(history?.createdAt);
							let date = new Date(timestamp);

							// Format the date to 12-hour format with AM/PM
							let options = {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
								hour12: true,
							};
							let formattedDate = date.toLocaleString('en-US', options);
							var f = 1;
							return (
								<Container>
									<Accordion open={open} toggle={toggle} className='mt-1'>
										<AccordionItem>
											<AccordionHeader targetId={i}>
												{history?.doctorName} - {formattedDate}
											</AccordionHeader>
											<AccordionBody accordionId={i}>
												<>
													{history?.historyData?.map((page, pageIndex) => (
														<Table
															bordered
															className='custom-table'
															key={`table-${pageIndex}`}>
															<tbody>
																{page.map((item, idx) => {
																	if (f === 0) {
																		f = 1;
																	} else if (
																		item.label === 'Header' ||
																		item.label === 'Paragraph'
																	) {
																		return (
																			<tr key={`${item.label}-${idx}`}>
																				<td colSpan={4}>
																					{item.label === 'Header' ? (
																						<h3>
																							{Array.isArray(item.value)
																								? item.value.join(', ')
																								: item.value}
																						</h3>
																					) : (
																						<p>
																							{Array.isArray(item.value)
																								? item.value.join(', ')
																								: item.value}
																						</p>
																					)}
																				</td>
																			</tr>
																		);
																	} else {
																		return (
																			<tr key={`${item.label}-${idx}`}>
																				<td>
																					<b>{item.label}: </b>
																				</td>
																				<td>
																					{item.type === 'textarea' &&
																					item.value !== undefined
																						? item.value
																						: Array.isArray(item.value) &&
																						  item.type === 'multiselect'
																						? Array.isArray(item.value) &&
																						  item.value
																								.map((option) => option.label)
																								.join(', ')
																						: item.type === 'checkbox'
																						? Array.isArray(item.value) &&
																						  item.value
																								.map((option) => option)
																								.join(', ')
																						: item.value}
																				</td>
																				{page[idx + 1] &&
																				page[idx + 1].label !== 'Header' &&
																				page[idx + 1].label !== 'Paragraph' ? (
																					<>
																						<div className='d-none'>
																							{(f = 0)}
																						</div>
																						<td>
																							<b>{page[idx + 1].label}: </b>
																						</td>
																						<td>
																							{page[idx + 1].type ===
																								'textarea' &&
																							page[idx + 1].value !== undefined
																								? page[idx + 1].value
																								: Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].type ===
																										'multiselect'
																								? Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].value
																										.map(
																											(option) => option.label
																										)
																										.join(', ')
																								: page[idx + 1].type ===
																								  'checkbox'
																								? Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].value
																										.map((option) => option)
																										.join(', ')
																								: page[idx + 1].value}
																						</td>
																					</>
																				) : null}
																			</tr>
																		);
																	}
																})}
															</tbody>
														</Table>
													))}
												</>
											</AccordionBody>
										</AccordionItem>
									</Accordion>
								</Container>
							);
						})}
					</Tab>
				</Tabs>
			</Container>
		</div>
	);
};

export default GetUserPrescriptionsScreen;
