/** @format */
import Message from './Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Meta from './Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getTimeslots } from '../redux/userApiCalls';
import { paymentStatusvisitedPatientsReset } from '../redux/updatePaymentStatusinVisitedPatient';
import {
	//
	//changeToVisited,
	changeToOnHold, //
	changeSlot, //
	getDoctorVisitedPatientsforParticularDate,
} from '../redux/adminRedux/adminApiCalls';
// import Accordion from 'react-bootstrap/Accordion';

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import { doctorBookingGetReset } from '../redux/adminRedux/Booking_Management/getBooking';
import { changeOnHoldReset } from '../redux/adminRedux/Booking_Management/changeOnHold';
import { changeSlotReset } from '../redux/adminRedux/Booking_Management/changeSlot';
import Form from 'react-bootstrap/Form';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../redux/allMessages';

import { TimeSlotGetReset } from '../redux/getTimeSlots';
import { Table } from 'react-bootstrap';
import {
	getMyAppointments,
	paymentStatusUpdate,
	paymentStatusvisitedPatients,
} from '../redux/doctorRedux/doctorApiCalls';
import { getMyAppointmentsReset } from '../redux/doctorRedux/Appointment_Management/getMyAppointments';
import { useParams } from 'react-router-dom';
//Date picker

const PatientsVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { loading, error, success } = useSelector(
		(state) => state.paymentStatusvisitedPatients
	);

	const doctorAppointmentDates =
		doctorInfo === undefined
			? receptionInfo?.user?.appointmentDates
			: doctorInfo?.user?.appointmentDates;

	const [visitedDate, setVisitedDate] = useState('');
	const [searchClicked, setSearchClicked] = useState(false);
	const handleDateSelect1 = (eventKey) => {
		setVisitedDate(eventKey);
		setSearchClicked(false);
	};

	//Date Format
	var days = [];
	let slotArrayVisited = [];
	const todaydate = new Date();
	todaydate.setDate(new Date().getDate());

	const today = todaydate.toISOString().split('T')[0];
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ('0' + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);
	const today1 = new Date();
	const year = today1.getFullYear();
	const month = today1.getMonth() + 1; // Months are zero-indexed
	const day = today1.getDate();

	// Form a number in YYYYMMDD format
	const numericDate = year * 10000 + month * 100 + day;
	const todaymax = new Date().toISOString().split('T')[0];
	let dateModified = numericDate;
	console.log(todaymax);

	function isDateGreaterThanToday(dateToCheck) {
		// Get today's date
		const today = new Date();

		// Get tomorrow's date
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate());
		// Convert the input date string to a Date object
		const inputDate = new Date(dateToCheck);

		// Compare the input date with today's date
		return inputDate > tomorrow;
	}

	const searchForVisited = async () => {
		if (visitedDate == '') {
			alert('Please select the date');
		} else {
			const isGreater = isDateGreaterThanToday(visitedDate);
			if (isGreater) {
				alert('Date cannot be greater than today');
				setVisitedDate(todaymax);
			} else {
				const tempArr1 = visitedDate.split('-');
				console.log(visitedDate);

				const dateModified1 = tempArr1[0] + '' + tempArr1[1] + '' + tempArr1[2];
				setSearchClicked(true);
				dispatch(
					getDoctorVisitedPatientsforParticularDate(
						doctorInfo === undefined
							? receptionInfo?.user?.doctorId
							: doctorInfo?.user?._id,
						parseInt(dateModified1),
						clinicName
					)
				);
			}
		}
	};

	useEffect(() => {
		const tempArr1 = visitedDate.split('-');
		const dateModified1 = tempArr1[0] + '' + tempArr1[1] + '' + tempArr1[2];
		dispatch(
			getDoctorVisitedPatientsforParticularDate(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id,
				parseInt(dateModified1),
				clinicName
			)
		);
	}, [loading]);
	const getVisiPat = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);

	console.log(getVisiPat);

	const [showv, setShowV] = useState(false);
	const handleClose = () => {
		setDiscount(0);
		setModeOfPayment('');
		setDiscountReason('');
		setAdditionalCharges([]);
		setShowV(false);
	};
	const [userId_payemntv, setuserId_payemntv] = useState('');
	const [prescriptionId_payemntv, setprescriptionId_payemntv] = useState('');
	const [userIdx, setuserIdx] = useState(0);
	const handleUpdatePaymentStatus1 = (userId, prescriptionId, index, mno) => {
		setShowV(true);
		setuserId_payemntv(userId);
		setprescriptionId_payemntv(prescriptionId);
		setuserIdx(index);
		setDiscount(0);
		setModeOfPayment('');
		setDiscountReason('');
		setAdditionalCharges([]);
		setMno(mno);
		setTotalAmountAfterDiscount(
			doctorInfo !== undefined
				? doctorInfo?.user?.opConsultationFee
				: receptionInfo?.user?.opConsultationFee
		);
	};

	const [discount, setDiscount] = useState('');
	const [discountReason, setDiscountReason] = useState('');
	const [modeOfPayment, setModeOfPayment] = useState('Choose');
	const [opConsultationFeedata, setopConsultationFeedata] = useState(
		doctorInfo !== undefined
			? doctorInfo?.user?.opConsultationFee
			: receptionInfo?.user?.opConsultationFee
	);
	let [amountPaidCash, setAmountPaidCash] = useState('');
	let [amountPaidOnline, setAmountPaidOnline] = useState('');
	const [additionalCharges, setAdditionalCharges] = useState([]);
	const [totalAmount, setTotalAmount] = useState(
		doctorInfo !== undefined
			? doctorInfo?.user?.opConsultationFee
			: receptionInfo?.user?.opConsultationFee
	);
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(
		doctorInfo !== undefined
			? doctorInfo?.user?.opConsultationFee
			: receptionInfo?.user?.opConsultationFee
	);
	const [mno, setMno] = useState('');

	const AddAdditionalCharge = () => {
		let addncharges = [...additionalCharges];
		addncharges?.push({
			additional_charge_key: '',
			additional_charge_amount: 0,
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline('');
		setAmountPaidCash('');
		setDiscount(0);
	};

	const editAdditionalChargeKey = (index, additional_charge_key) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return { ...addncharge, additional_charge_key: additional_charge_key };
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline('');
		setAmountPaidCash('');
		setDiscount(0);
	};

	const editAdditionalChargeAmount = (index, additional_charge_amount) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return {
					...addncharge,
					additional_charge_amount: Number(additional_charge_amount),
				};
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return (
				total +
				(Number(parseFloat(addncharge?.additional_charge_amount).toFixed(2)) ||
					0)
			); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(parseFloat(opConsultationFeedata).toFixed(2)) +
				Number(parseFloat(additionalChargesTotal).toFixed(2))
		);
		setTotalAmountAfterDiscount(
			Number(parseFloat(opConsultationFeedata).toFixed(2)) +
				Number(parseFloat(additionalChargesTotal).toFixed(2))
		);
		setAmountPaidOnline('');
		setAmountPaidCash('');
		setDiscount(0);
	};

	const deleteAdditionalCharge = (index) => {
		let addncharges = [...additionalCharges];
		addncharges?.splice(index, 1);
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return (
				total +
				(Number(parseFloat(addncharge?.additional_charge_amount).toFixed(2)) ||
					0)
			); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(parseFloat(opConsultationFeedata).toFixed(2)) +
				Number(parseFloat(additionalChargesTotal).toFixed(2))
		);
		setTotalAmountAfterDiscount(
			Number(parseFloat(opConsultationFeedata).toFixed(2)) +
				Number(parseFloat(additionalChargesTotal).toFixed(2))
		);
		setAmountPaidOnline('');
		setAmountPaidCash('');
		setDiscount(0);
	};

	// Function to handle change of discount percentage
	const handleDiscountChangeVisited = (event) => {
		if (event?.target?.value > 100) {
			alert('Discount cannot be greater than 100%');
		} else if (event?.target?.value < 0) {
			alert('Discount cannot be less than 0%');
		} else {
			setDiscount(event?.target?.value);
			let discountpercent = event?.target?.value;
			debugger;
			let AmountAfterDiscount =
				totalAmount -
				parseFloat(totalAmount * Number(discountpercent) * 0.01).toFixed(2);
			setTotalAmountAfterDiscount(
				Number(parseFloat(AmountAfterDiscount).toFixed(2))
			);
		}
		setAmountPaidOnline('');
		setAmountPaidCash('');
	};

	const handleAmounts = (amount, type) => {
		if (amount > totalAmountAfterDiscount) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else {
			if (type === 'cash') {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(parseFloat(amount).toFixed(2)));
				setAmountPaidOnline(
					Number(
						parseFloat(totalAmountAfterDiscount).toFixed(2) -
							parseFloat(amount).toFixed(2)
					)
				);
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(parseFloat(amount).toFixed(2)));
				setAmountPaidCash(
					Number(
						parseFloat(totalAmountAfterDiscount).toFixed(2) -
							parseFloat(amount).toFixed(2)
					)
				);
			}
		}
	};

	// Function to handle change of discount percentage
	// const handleDiscountChange = (event) => {
	// 	if (event?.target?.value > 100) {
	// 		alert("Discount cannot be greater than 100%");
	// 	} else if (event?.target?.value < 0) {
	// 		alert("Discount cannot be less than 0%");
	// 	} else {
	// 		const discountPercentage = parseFloat(event.target.value) || 0;
	// 		const discountedAmount =
	// 			initialAmount - (initialAmount * discountPercentage) / 100;
	// 		setDiscount(event.target.value);
	// 		setAmount(discountedAmount);
	// 	}
	// };

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split('T')[0];
	}

	// Function to handle form submission
	const handleSubmitVisited = () => {
		// Log all input values
		console.log('Discount:', discount);
		console.log('Discount Reason:', discountReason);
		console.log('Mode of Payment:', modeOfPayment);
		let bill = {
			AmountToBePaid: totalAmount,
			AmountPaid: Number(amountPaidCash) + Number(amountPaidOnline),
			AmountDue: 0,
			invoiceNumber:
				'INV' + getCurrentDateIST()?.split('-')?.join('') + mno + userIdx,
			prescription_charges: {
				AmountToBePaid: totalAmount,
				AmountDue: 0,
				discount: {
					discountAmount:
						Number(totalAmount) - Number(totalAmountAfterDiscount),
					discountReason: discountReason,
				},
				doctorId:
					doctorInfo === undefined
						? receptionInfo?.user?.doctorId
						: doctorInfo?.user?._id,
				doctorName:
					doctorInfo === undefined
						? receptionInfo?.user?.doctorName
						: doctorInfo?.user?.name,
				prescriptionAmount: opConsultationFeedata,
				additionalCharges: additionalCharges,
			},
			inventory_charges: {},
			billDate: getCurrentDateIST(),
			amountPaidOnline: amountPaidOnline,
			amountPaidCash: amountPaidCash,
		};

		console.log(
			totalAmount - totalAmountAfterDiscount,
			discountReason,
			totalAmount,
			prescriptionId_payemntv,
			'Cash',
			bill,
			clinicName
		);
		console.log(bill);

		amountPaidOnline = amountPaidOnline === '' ? 0 : amountPaidOnline;
		amountPaidCash = amountPaidCash === '' ? 0 : amountPaidCash;
		if (
			Number(amountPaidOnline) + Number(amountPaidCash) >
			Number(totalAmountAfterDiscount)
		) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else if (amountPaidOnline + amountPaidCash < totalAmountAfterDiscount) {
			alert(`Amount cannot be less than ${totalAmountAfterDiscount}`);
		} else if (
			additionalCharges.some(
				(item) =>
					item.additional_charge_key === '' ||
					item.additional_charge_amount === 0
			)
		) {
			alert('Fill all the additional charges');
		} else {
			dispatch(
				paymentStatusvisitedPatients(
					totalAmount - totalAmountAfterDiscount,
					discountReason,
					totalAmount,
					prescriptionId_payemntv,
					'Cash',
					bill,
					doctorInfo === undefined
						? receptionInfo?.user?.doctorId
						: doctorInfo?.user?._id,
					clinicName
				)
			);
		}

		setDiscount(0);
		setModeOfPayment('');
		setDiscountReason('');
		setAdditionalCharges([]);
		// Close the modal
		handleClose();
	};

	// submit for visited patients
	// const handleSubmitVisited = () => {
	// 	if (modeOfPaymentVisited === "Choose") {
	// 		alert("Choose Mode of payment.");
	// 	} else {
	// 		// Log all input values
	// 		console.log("Amount:", amountVisited);
	// 		console.log("Discount:", discountVisited);
	// 		console.log("Discount Reason:", discountReasonVisited);
	// 		console.log("Mode of Payment:", modeOfPaymentVisited);
	// 		console.log("Additional charges:", additionalChargesVisited);
	// 		console.log(
	// 			"" + (parseInt(amountVisited) + parseInt(additionalChargesVisited))
	// 		);
	// 		dispatch(
	// 			paymentStatusvisitedPatients(
	// 				discountVisited,
	// 				discountReasonVisited,
	// 				"" + (parseInt(amountVisited) + parseInt(additionalChargesVisited)),
	// 				userId_payemntv,
	// 				modeOfPaymentVisited,
	// 				doctorInfo === undefined
	// 					? receptionInfo?.user?.doctorId
	// 					: doctorInfo?.user?._id,
	// 				prescriptionId_payemntv,
	// 				userIdx,
	// 				clinicName
	// 			)
	// 		);
	// 		// Additional logic for handling form submission, e.g., sending data to the server
	// 		// ...
	// 		setAmountVisited(initialAmount);
	// 		setDiscountVisited(0);
	// 		setModeOfPaymentVisited("");
	// 		setDiscountReasonVisited("");
	// 		setAdditionalChargesVisited(0);
	// 		// Close the modal
	// 		handleClose();
	// 	}
	// };

	// const handleDiscountChangeVisited = (event) => {
	// 	const discountPercentage = parseFloat(event.target.value) || 0;
	// 	const discountedAmount =
	// 		initialAmount - (initialAmount * discountPercentage) / 100;
	// 	setDiscountVisited(event.target.value);
	// 	setAmountVisited(discountedAmount);
	// };

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	const exportToExcel = async () => {
		let tempObj =
			getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data
				?.visited_patients;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				'Patient Name': tempObj[i].name,
				'Slot ': tempObj[i].slot,
				'Mobile No': tempObj[i].mobilenumber,
				'Amount Paid': tempObj[i].billDetails.paidAmount,
				// 'Paid Through': tempObj[i].modeOfPayment,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + 'dataaaaaa');

		const ws = XLSX.utils.json_to_sheet(tempArr);

		// Set column widths (example: set the width of the first column to 20)
		ws['!cols'] = [
			{ wch: 20 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 20 },
		];

		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			doctorInfo === undefined
				? receptionInfo?.user?.doctorName
				: doctorInfo?.user?.name + '_' + visitedDate
		);
		console.log('saved');
	};
	const [showPopup, setShowPopup] = useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(paymentStatusvisitedPatientsReset());
	};

	return (
		<div className='pageSize'>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{doctorInfo ? (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/bhuvanshomeopathy/doctor/patientstobevisited`}>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/bhuvanshomeopathy/doctor/patientsvisited`}>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/bhuvanshomeopathy/reception/patientstobevisited`}>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/bhuvanshomeopathy/reception/patientsvisited`}>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div>
				<h1 className='header-center'>Visited Patients</h1>
				<Container className='header-center mt-4 mb-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Select the filters{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<input
								type='date'
								className='form-control'
								onChange={(e) => handleDateSelect1(e.target.value)}
								max={todaymax}
							/>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<Button
								id='searchButton1'
								className='btn-primary'
								onClick={searchForVisited}>
								Search
							</Button>
						</Col>
					</Row>
				</Container>

				<Container>
					<button
						className='btn btn-success offset-md-10 mb-2'
						onClick={(e) => exportToExcel()}>
						<i className='fa fa-download'></i>&nbsp;Download
					</button>
					<Table striped bordered hover className='custom-table'>
						<thead>
							<tr>
								<th></th>
								<th scope='col'>Patient No</th>
								<th scope='col'>Patient Name</th>
								<th scope='col'>Slot</th>
								<th scope='col'>Patient Mobile Number</th>
								<th scope='col'>Payment</th>
							</tr>
						</thead>
						{visitedDate !== '' &&
							searchClicked &&
							getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients?.map(
								(
									{
										name,
										mobilenumber,
										prescriptionId,
										slot,
										hasPaid,
										userId,
										billDetails,
									},
									index
								) => (
									<tbody>
										<tr>
											<td></td>
											<td>{index + 1}</td>
											<td>{name}</td>
											<td>{slot}</td>
											<td>{mobilenumber}</td>
											<td>
												{billDetails?.billId === '' ? (
													<button
														className='btn btn-success'
														onClick={() =>
															handleUpdatePaymentStatus1(
																userId,
																prescriptionId,
																index,
																mobilenumber
															)
														}>
														<i className='	fa fa-credit-card'></i>
													</button>
												) : (
													<div>
														<button className='btn btn-success'>
															<i className='fa fa-check'></i>
														</button>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								)
							)}
					</Table>
				</Container>
				<Modal show={showv} onHide={handleClose} size='lg'>
					<Modal.Header closeButton>
						<Modal.Title>Update Payment Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>
								Consultation Fee:
							</div>
							<div className='col-8'>Rs {opConsultationFeedata}</div>
						</div>

						{additionalCharges?.length > 0 && (
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>
									Additional Charges:{' '}
								</div>
							</div>
						)}
						{additionalCharges?.map((addncharge, index) => (
							<div className='row mt-2'>
								<input
									type='text'
									className='offset-md-1 col-md-5 col-6 form-control'
									placeholder='Enter Name'
									value={addncharge?.additional_charge_key}
									onChange={(e) =>
										editAdditionalChargeKey(index, e.target.value)
									}
								/>
								<input
									type='number'
									className='offset-md-1 col-md-3 col-6 form-control'
									placeholder='Enter Amount'
									value={addncharge?.additional_charge_amount}
									onChange={(e) =>
										editAdditionalChargeAmount(index, e.target.value)
									}
								/>
								<button
									className='btn btn-danger ml-3'
									onClick={() => deleteAdditionalCharge(index)}>
									<i className='fa fa-trash'></i>
								</button>
							</div>
						))}
						<div className='row mt-2 d-flex justify-content-center'>
							<button
								className='btn btn-secondary'
								onClick={AddAdditionalCharge}>
								Add Additional Charges
							</button>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Discount (%):</div>
							<input
								type='number'
								className='col-8 form-control'
								value={discount}
								onChange={handleDiscountChangeVisited}
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Discount Reason:</div>
							<input
								type='text'
								className='col-8 form-control'
								value={discountReason}
								onChange={(e) => setDiscountReason(e.target.value)}
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Total Amount:</div>
							<div className='col-8'>Rs {totalAmountAfterDiscount}</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<input
							type='number'
							className='col-3 form-control'
							placeholder='Amount Paid Cash'
							value={amountPaidCash}
							onChange={(e) => handleAmounts(e.target.value, 'cash')}
						/>
						<input
							type='number'
							className='col-3 form-control'
							placeholder='Amount Paid Online'
							value={amountPaidOnline}
							onChange={(e) => handleAmounts(e.target.value, 'online')}
						/>
						<Button variant='primary' onClick={handleSubmitVisited}>
							Mark as Paid
						</Button>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};

export default PatientsVisitedScreen;
